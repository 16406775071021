import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { omnichatApiUrls } from "./urls";

export const omnichatApi = createApi({
  reducerPath: "omnichat",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_OMNICHAT_URL,
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as any).token.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["RemoveGWOrderFeature"],
  endpoints: (builder) => ({
    sendSms: builder.mutation<any, { contact_number: string; message: string }>(
      {
        query: (body) => ({
          url: omnichatApiUrls.sendSms,
          method: "POST",
          body,
        }),
      }
    ),

    updateLastActivityTime: builder.mutation<any, { uuid: string }>({
      query: (body) => ({
        url: omnichatApiUrls.updateLastActivityTime,
        method: "POST",
        body,
      }),
    }),

    updateRemoveGwOrderFeature: builder.mutation<
      any,
      { uuid: string; status: boolean }
    >({
      query: (body) => ({
        url: omnichatApiUrls.updateRemoveGwOrderFeature,
        method: "POST",
        body,
      }),
      invalidatesTags: ["RemoveGWOrderFeature"],
    }),

    getRemoveGwOrderFeature: builder.query<any, string>({
      query: omnichatApiUrls.getRemoveGwOrderFeature,
      providesTags: ["RemoveGWOrderFeature"],
    }),
  }),
});

export const {
  useSendSmsMutation,
  useUpdateLastActivityTimeMutation,
  useUpdateRemoveGwOrderFeatureMutation,
  useGetRemoveGwOrderFeatureQuery,
} = omnichatApi;
