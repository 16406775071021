import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetMemberQuery } from "src/api/main";
import { Banner, TierTypesMapping } from "src/api/types";
import { StickyNotification } from "src/components";
import { ROUTES } from "src/types/routes";

const AddPartnerBanner = ({ banner }: { banner: Banner }) => {
  const navigate = useNavigate();
  const { data: member } = useGetMemberQuery();
  const { rx1463InvitePartnerFromApp } = useFlags();

  if (
    !rx1463InvitePartnerFromApp ||
    !TierTypesMapping.COUPLE.includes(member?.tier_type) ||
    member?.partner
  ) {
    return <></>;
  }

  const onClick = () => {
    navigate(ROUTES.ADD_PARTNER_FORM);
  };

  return (
    <StickyNotification
      definedColor={banner.color}
      Icon={() => <img src={banner.icon} alt="banner_icon" />}
      title={banner.title}
      Subtitle={<>{banner.text}</>}
      onClick={onClick}
    />
  );
};

export default AddPartnerBanner;
