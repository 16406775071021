import { useMemo } from "react";
import { useGetBannersQuery } from "src/api/cms";
import Drawers from "src/components/Drawers";
import { NotificationsWrapper } from "../../../styles";
import useStateResolver from "../utils/stateResolver";
import CmsBanner from "./CmsBanner";
import { customBanners } from "./CustomBanners";

const CmsBanners = () => {
  const { data: banners } = useGetBannersQuery();
  const prioritizedBanner = useMemo(
    () => banners?.find((item) => item.prioritized),
    [banners]
  );

  const state = useStateResolver(prioritizedBanner?.state);

  const filteredBanners = useMemo(() => {
    if (!banners) return [];
    if (prioritizedBanner && state) return [prioritizedBanner];
    return banners;
  }, [banners, state, prioritizedBanner]);

  return (
    <NotificationsWrapper>
      {filteredBanners?.map((banner) => {
        const Component = customBanners[banner.id] || CmsBanner;
        return <Component banner={banner} key={banner.id} />;
      })}
      <Drawers />
    </NotificationsWrapper>
  );
};

export default CmsBanners;
