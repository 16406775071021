import { useMemo } from "react";
import { Button, GrowthworkLabel, WistiaEmbed } from "src/components";
import { Growthwork, GWPlatforms, MemberGrowthWorkStatus } from "src/api/types";
import useLocationState from "src/hooks/useLocationState";
import { useCompleteGrowthworkMutation, useGetMemberQuery } from "src/api/main";
import { Typography } from "@mui/material";
import { ButtonWrapper, PlayerContent, PlayerWrapper } from "../../styles";
import { useGetWistiaTaskPageQuery } from "src/api/cms";

export interface GrowthworkProps extends Growthwork {
  uuid: string;
}

const Player = ({ onComplete }: { onComplete: () => void }) => {
  const growthwork = useLocationState<GrowthworkProps>("growthwork");
  const referer = useLocationState<string>("referer");
  const [completeGrowthwork] = useCompleteGrowthworkMutation();
  const { data: member } = useGetMemberQuery();
  const { data: wistiaTaskPage } = useGetWistiaTaskPageQuery();

  const isAudio = useMemo(() => {
    return !!growthwork?.baselink.includes("async_audio");
  }, [growthwork]);

  const handleComplete = () => {
    if (!member || !growthwork) return;

    completeGrowthwork({
      member: member.uuid,
      growthworkId: growthwork.uuid,
      status: MemberGrowthWorkStatus.REVIEWED,
    })
      .unwrap()
      .then(onComplete);
  };

  const disabled = !growthwork?.uuid;
  const metadata = `g${growthwork?.relation_cms_id}_${member?.email}`;

  // route starts with `/`. Home page is just `/`
  const refererPrefix =
    (referer?.length || 0) > 1 ? `${referer?.substring(1)}-` : "";

  return (
    <>
      <PlayerWrapper>
        <PlayerContent>
          <GrowthworkLabel
            type={isAudio ? GWPlatforms.AUDIO : GWPlatforms.WISTIA}
          />
          <Typography variant="h1">{growthwork?.gw_short_title}</Typography>
          <WistiaEmbed
            link={growthwork?.baselink || ""}
            isAudio={isAudio}
            email={metadata || ""}
          />
          <Typography>{growthwork?.gw_description}</Typography>
        </PlayerContent>
      </PlayerWrapper>
      <ButtonWrapper>
        {!disabled && wistiaTaskPage && (
          <Button
            id={`${refererPrefix}complete-${
              isAudio ? "audio" : "video"
            }-growth`}
            onClick={handleComplete}
          >
            {isAudio
              ? wistiaTaskPage.audio_button
              : wistiaTaskPage.video_button}
          </Button>
        )}
      </ButtonWrapper>
    </>
  );
};

export default Player;
