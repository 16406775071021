import React from "react";
import { Item, List, Wrapper } from "./styles";
import { Typography } from "src/components";

const items = [
  "Double-check for typos",
  "Log in using your phone number",
  "Review your messages from OurRitual to confirm your sign-up is complete",
];

const WaysToResolveList = () => {
  return (
    <Wrapper>
      <Typography variant="body3">
        To resolve this, try the following:
      </Typography>
      <List>
        {items.map((item) => (
          <Item key={item}>
            <Typography variant="body3">{item}</Typography>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

export default WaysToResolveList;
