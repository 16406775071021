import { Button, InfoLayout } from "src/components";
import { Wrapper, AudioWrapper } from "./styles";
import { useCompletePart, useGetExpertQueryResolver } from "src/api/resolvers";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import Close from "src/assets/icons/Close";
import useAwsDownload from "src/hooks/useAwsDownload";
import { useEffect, useMemo, useState } from "react";
import { useGetMemberQuery } from "src/api/main";
import feedbackImg from "src/assets/images/feedback.png";
import ControlledAudioPlayer from "src/components/ControlledAudioPlayer";

const ExpertFeedback = () => {
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState("");
  const [audio, setAudio] = useState<string[]>([]);
  const { data: member } = useGetMemberQuery();
  const { data: expert, isFetching } = useGetExpertQueryResolver();

  const { getLatestKey, getData } = useAwsDownload();

  useEffect(() => {
    const getFeedback = async () => {
      if (!member || !expert) return;
      const key = await getLatestKey(`${member.email}/${expert.uuid}`);
      const data: string = await getData(key);
      if (data.startsWith("data:audio/mp3")) setAudio(data.split(" "));
      else setFeedback(data);
    };

    getFeedback();
    // eslint-disable-next-line
  }, [member, expert]);

  const handleClick = useCompletePart();

  const isLoading = useMemo(() => {
    const feedbackReady = feedback || audio.length;
    return isFetching || !feedbackReady;
  }, [audio.length, feedback, isFetching]);

  return (
    <Wrapper>
      <Close onClick={() => navigate(ROUTES.HOME)} />
      <InfoLayout
        Icon={() => <img src={feedbackImg} alt="feedback" />}
        title={expert ? `${expert.first_name}’s feedback for you` : ""}
        subtitle={feedback || ""}
        isLoading={isLoading}
        AdditionalContent={
          <AudioWrapper>
            {audio.map((src) => (
              <ControlledAudioPlayer src={src} title="Expert feedback" />
            ))}
          </AudioWrapper>
        }
      >
        <Button id="start-first-week" onClick={handleClick}>
          Complete Reviewing
        </Button>
      </InfoLayout>
    </Wrapper>
  );
};

export default ExpertFeedback;
