import React from "react";
import SubscriptionDetails from "./SubscriptionDetails";
import SupportFooter from "./SupportFooter";
import { Caption, Layout, Title, WrapperButton } from "./styles";
import { useGetMemberSubscriptionStatusQueryResolver } from "src/api/resolvers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Button } from "src/components";
import { ROUTES } from "src/types/routes";
import { useNavigate } from "react-router-dom";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";
import useIsMatchingExpertChanged from "src/hooks/useIsMatchingExpertChanged";

const Subscription = () => {
  const { membershipPaused, reactivateMembership } = useFlags();
  const { data: membershipStatus, isFetching } =
    useGetMemberSubscriptionStatusQueryResolver();
  const navigate = useNavigate();
  const {matchingSession} = useFlags()
  const isMatchingCategory = useIsMatchingPlanCategory();
  const isMatchginExpertChanged = useIsMatchingExpertChanged();
  const processModal = () => {
    navigate(ROUTES.REACTIVATE_SUBSCRIPTION);
  };

  const upgradeClicked = () => {
    navigate(ROUTES.PLANS);
  };

  const caption =
  isMatchingCategory
    ? ''
    : membershipStatus?.isSubscriptionPaused && membershipPaused
    ? `Your Membership is paused ${
        membershipStatus?.pauseUntilDate
          ? "until " + membershipStatus?.pauseUntilDate
          : ""
      }`
    : `Your Membership will renew automatically`;

  return (
    <Layout>
      <Title variant="headline2">Purchases</Title>
      <SubscriptionDetails />
      {!isFetching && <Caption variant="caption">{caption}</Caption>}
        {membershipStatus?.isSubscriptionPaused && reactivateMembership && (
        <WrapperButton>
          <Button
            id="popup_reactivatemembership_confirmed"
            onClick={processModal}
          >
            Reactivate membership
          </Button>
        </WrapperButton>
      )}
        {isMatchingCategory && matchingSession && isMatchginExpertChanged &&(
        <WrapperButton>
          <Button
            id="pricing_matchingsessionupgrade_subscription_clicked"
            onClick={upgradeClicked}
          >
            Upgrade
          </Button>
        </WrapperButton>
      )}
      <SupportFooter />
    </Layout>
  );
};

export default Subscription;
