import { useSessionShowEventMutation } from "src/api/main";
import { useGetSessionDetailsQueryResolver } from "src/api/resolvers";

const useJoinSession = () => {
  const { data: session } = useGetSessionDetailsQueryResolver();
  const [triggerSessionShow] = useSessionShowEventMutation();

  const joinSession = (sessionToJoin = session) => {
    const redirectLink = sessionToJoin?.session_link || "";
    const sessionId = sessionToJoin?.next_session_event_id || "";

    if (sessionToJoin?.session_link) {
      triggerSessionShow(sessionId).then(() => {
        window.location.href = redirectLink;
      });
    }
  };

  return { joinSession };
};

export default useJoinSession;
