import React from "react";
import { Button, ButtonsRow } from "./styles";
import useGetSessionLinks from "../../../utils/useGetSessionLinks";
import { Session } from "src/api/types";
import useActions from "../../../utils/useActions";
import { AlertTypes } from "../../../utils/reducer";
import { useGetSessionRescheduleRestrictionQuery } from "src/api/cms";
import { getTimeDifference } from "../../../utils/helpers";


const ManageSessionButtons = ({ session }: { session: Session }) => {
  const { cancelLink, rescheduleLink } = useGetSessionLinks(session);
  const { setCalendlyLink, showAlert } = useActions();
  const { data: restriction } = useGetSessionRescheduleRestrictionQuery();

  const onClick = (link: string, alertType: AlertTypes) => () => {
    if (
      getTimeDifference(session?.next_session) <
      Number(restriction?.hours_before_start)
    ) {
      showAlert({ alertLink: link, alertType });
    } else {
      setCalendlyLink(link);
    }
  };

  return (
    <ButtonsRow>
      <Button
        id="cancel-session"
        color="outlined-secondary"
        onClick={onClick(cancelLink, AlertTypes.CANCEL)}
      >
        Cancel
      </Button>
      <Button
        id="reschedule-session"
        color="outlined-secondary"
        onClick={onClick(rescheduleLink, AlertTypes.RESCHEDULE)}
      >
        Reschedule
      </Button>
    </ButtonsRow>
  );
};

export default ManageSessionButtons;
