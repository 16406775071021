import React, { PropsWithChildren, useEffect, useState } from "react";
import ConnectionFailed from "../ErrorScreens/ConnectionFailed";
import useOfflineImage from "src/hooks/useOfflineImage";

const NetworkStatusWrapper = ({ children }: PropsWithChildren) => {
  const [online, setOnline] = useState(true);
  const image = useOfflineImage("/images/connection_failed-illustration.webp");

  useEffect(() => {
    window.ononline = () => setOnline(true);
    window.onoffline = () => setOnline(false);

    return () => {
      window.ononline = null;
      window.onoffline = null;
    };
  }, []);

  if (!online) {
    return <ConnectionFailed illustration={image} />;
  }

  return <>{children}</>;
};

export default NetworkStatusWrapper;
