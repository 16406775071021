import { PageLayout } from "src/components";
import {
  Growthworks,
  ModuleBanner,
  Notifications,
  WeeksLine,
} from "./components";
import {
  useGetActiveModuleQueryResolver,
  useGetCurrentPart,
  useGetMemberModulesQueryResolver,
  useGetModuleCms,
} from "src/api/resolvers";
import { Wrapper } from "./styles";
import Loading from "src/components/Loading";
import { AddToHomeShortcut } from "src/components";
import ScheduleSession from "./components/SessionSection";
import {
  useGetContactUsContentQuery,
  useGetGrowthworkLabelsQuery,
  useGetTaskCompletedScreensQuery,
} from "src/api/cms";

import useRedirection from "./utils/useRedirection";
import DesktopView from "../DesktopView";
import { isMobile } from "src/utils/isMobile";
import ConfigurationError from "./components/ConfigurationError";
import { CompleteProfileBanner } from "src/pages/Home/components/CompleteProfileBanner";
import React from "react";
import { useCompleteProfile } from "src/contexts/CompleteProfileContext";
import {PartCompletedMatchingBanner} from "src/pages/Home/components/MatchingSubscribeBanner";
import {useFlags} from "launchdarkly-react-client-sdk";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";
import {CurrentPartStatuses} from "src/api/types";
import useIsMatchingExpertChanged from "src/hooks/useIsMatchingExpertChanged";

const Home = () => {
  const { data: module } = useGetActiveModuleQueryResolver();
  const { data: moduleCms, isFetching: isModulesFetching } = useGetModuleCms();
  const { data: part, isLoading: isPartLoading } = useGetCurrentPart();
  const { isSurveyCompleted } = useCompleteProfile();
  const {matchingSession} = useFlags()
  const isMatchginExpertChanged = useIsMatchingExpertChanged();
  const isMatchingCategory = useIsMatchingPlanCategory();
  useGetTaskCompletedScreensQuery();
  useGetGrowthworkLabelsQuery();
  useGetContactUsContentQuery();

  const { isFetching } = useRedirection();

  if (isFetching || isModulesFetching || isPartLoading) {
    return <Loading />;
  }

  if (!isFetching && !isMobile()) {
    return <DesktopView />;
  }
  return (
    <PageLayout>
      <ScheduleSession />
      { module?.current_part_status !== CurrentPartStatuses.STARTED &&
        isMatchingCategory &&
        matchingSession && isMatchginExpertChanged && <PartCompletedMatchingBanner />}
      {!isSurveyCompleted && <CompleteProfileBanner />}
      <Notifications />
      {module && moduleCms && part ? (
        <>
          <Wrapper>
            <WeeksLine
              length={moduleCms.weeks_length}
              currentPart={module.current_part}
            />
            <ModuleBanner
              partGoal={part.part_goal}
              partDescription={part.part_description}
            />
            <AddToHomeShortcut />
          </Wrapper>
          <Growthworks />
        </>
      ) : (
        <ConfigurationError />
      )}
    </PageLayout>
  );
};

export default Home;
