import React from "react";
import { useGetChatPageQuery } from "src/api/cms";
import LayoutWithoutHeader from "src/components/LayoutWithoutHeader";
import FAQFooter from "./components/FAQFooter";
import { Subtitle, Title } from "./styles";
import Loading from "src/components/Loading";
import Chats from "./components/Chats";
import { useStream } from "../Chat/contexts/StreamContext";

const ChatInitial = () => {
  const { data: cmsData, isFetching } = useGetChatPageQuery();
  const { channelsLoading } = useStream();

  if (isFetching || channelsLoading) {
    return <Loading />;
  }

  return (
    <LayoutWithoutHeader Footer={FAQFooter}>
      <Title>{cmsData?.title}</Title>
      <Subtitle>{cmsData?.subtitle}</Subtitle>
      <Chats />
    </LayoutWithoutHeader>
  );
};

export default ChatInitial;
