import React from "react";
import LayoutWithImage from "../Onboarding/components/Layout/LayoutWithImage";
import { LoginWithEmail, LoginWithPhone } from "src/components/LoginButtons";
import { Wrapper } from "./styles";
import { ROUTES } from "src/types/routes";
import useOnboardingSteps from "../Onboarding/utils/useOnboardingSteps";
import { isMobile } from "src/utils/isMobile";
import { MemberTier } from "src/api/types";

const CreateAccount = () => {
  const steps = useOnboardingSteps();
  const queryParams = new URLSearchParams(window.location.search);
  const tierType = queryParams.get("tier_type");
  const redirectTo =
    tierType !== null && Number(tierType) === MemberTier.MATCHING
      ? window.location.origin + ROUTES.ONBOARDING_BOOK_MATCHING_SESSION
      : window.location.origin + ROUTES.ONBOARDING_BOOK_WELCOME_SESSION;
  const additionalParams = {
    redirectTo: redirectTo,
    onboarding: "true",
    steps: steps.map(({ loginKey }) => loginKey).join("|"),
  };

  return (
    <LayoutWithImage
      image={require(`src/assets/images/onboarding_image2${
        isMobile() ? "-mobile" : ""
      }.webp`)}
      title="Verify your account"
      subtitle="We will validate and complete your account by verifying your email address or your phone number."
    >
      <Wrapper>
        <LoginWithEmail
          text="Continue with email address"
          loginParams={additionalParams}
        />
        <LoginWithPhone
          text="Continue with phone number"
          loginParams={additionalParams}
        />
      </Wrapper>
    </LayoutWithImage>
  );
};

export default CreateAccount;
