import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "src/components";
import MainLayout from "src/components/PageLayout";

const OldProfile = () => {
  const { logout } = useAuth0();

  return (
    <MainLayout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "10px",
          gap: "20px",
        }}
      >
        <Button
          id="logout"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Logout
        </Button>
      </div>
    </MainLayout>
  );
};

export default OldProfile;
