import { useFlags } from "launchdarkly-react-client-sdk";
import {
  useGetActiveModuleQueryResolver,
  useGetMemberFeedbacksQueryResolver,
  useGetModuleCms,
} from "src/api/resolvers";
import { FeedbackTypes, GwFeedbackType } from "src/api/types";
import { openFeedback } from "src/store/feedbackReducer";
import { useAppDispatch } from "src/store/hooks";

const partByTypeMapping = {
  [FeedbackTypes.VIDEO]: 2,
  [FeedbackTypes.QUESTIONNAIRE]: 3,
  [FeedbackTypes.VIDEO_JOURNAL]: 4,
};

const useGwFeedback = (type: GwFeedbackType) => {
  const { data: memberFeedback } = useGetMemberFeedbacksQueryResolver();
  const { showGrowthworksFeedback } = useFlags();

  const { data: module } = useGetActiveModuleQueryResolver();
  const { data: pathwayCMS } = useGetModuleCms();

  const dispatch = useAppDispatch();

  const shouldOpenFeedback = () => {
    return (
      !memberFeedback?.find((feedback) => type === feedback.type) &&
      showGrowthworksFeedback &&
      module?.current_part === partByTypeMapping[type]
    );
  };

  const requestFeedback = ({ gw_id }: { gw_id: string | number }) => {
    if (shouldOpenFeedback()) {
      dispatch(
        openFeedback({
          type,
          event_fields: {
            gw_id,
            part: module?.current_part,
            pathway_name: pathwayCMS?.name,
            pathway_id: pathwayCMS?.id,
          },
        })
      );
    }
  };

  return requestFeedback;
};

export default useGwFeedback;
