import React, { PropsWithChildren } from "react";
import {
  useGetCompletedMatchingSessionQueryResolver,
  useGetMemberSubscriptionStatusQueryResolver,
  useGetUpcomingSessions,
} from "src/api/resolvers";
import { Button } from "src/components";
import { ButtonProps } from "src/components/Button";

import { SessionType } from "src/api/types";
import useBookSessionAction from "../utils/useBookSessionAction";
import { useReactivation } from "src/contexts/ReactivationContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";

const BookSessionButton = (props: Partial<ButtonProps> & PropsWithChildren) => {
  const { data: scheduledSessions } = useGetUpcomingSessions();
  const {
    data: membershipStatus,
    isFetching: isMemberSubscriptionStatusFetching,
  } = useGetMemberSubscriptionStatusQueryResolver();
  const { manageModalReactivation } = useReactivation();
  const onClick = useBookSessionAction();
  const { data: matchingSessionDetails } =
    useGetCompletedMatchingSessionQueryResolver();

  const navigate = useNavigate();

  const redirectToPlans = () => {
    navigate(ROUTES.PLANS);
  };

  const { reactivateMembership, matchingSession } = useFlags();

  if (scheduledSessions?.some(({ type }) => type === SessionType.WELCOME)) {
    return <></>;
  }

  return (
    <Button
      {...props}
      id="schedule-session"
      onClick={(e) => {
        if (
          matchingSessionDetails?.is_matching &&
          matchingSessionDetails?.is_matching_session_done &&
          matchingSession
        ) {
          redirectToPlans();
        }
        if (membershipStatus?.isSubscriptionPaused && reactivateMembership) {
          manageModalReactivation();
        } else {
          e.stopPropagation();
          onClick();
        }
      }}
    >
      {props.children}
    </Button>
  );
};

export default BookSessionButton;
