import React, { useEffect, useState } from "react";
import {
  useGetMemberQuery,
  useLazyGetMemberStatusByPhoneNumberQuery,
} from "src/api/main";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginTypes, NoAccountFoundTypes } from "src/types/login";
import { ROUTES } from "src/types/routes";
import { MemberStatus } from "src/api/types";
import { parseInput } from "src/pages/DesktopView/helpers";
import {
  PhoneInputForm,
  PhoneInputWrapper,
  TextWrapper,
} from "src/pages/Onboarding/components/AllSetScreen/styles";
import {
  DesktopViewDescription,
  DesktopViewTitle,
  FinishedOnboardingDescription,
  FinishedOnboardingTitle,
} from "src/pages/Onboarding/components/AllSetScreen/Texts";
import { Button, Input } from "src/components";

const PhoneNumberLogin = () => {
  const { data: member } = useGetMemberQuery();
  const [phoneNumber, setPhoneNumber] = useState("");

  const [
    getMemberStatusByPhoneNumber,
    {
      data: memberStatusByPhoneNumber,
      isError: isErrorFindingMemberByPhoneNumber,
    },
  ] = useLazyGetMemberStatusByPhoneNumberQuery();

  const navigate = useNavigate();
  const { state } = useLocation() as {
    state: { type: LoginTypes; email?: string };
  };

  useEffect(() => {
    if (isErrorFindingMemberByPhoneNumber) {
      navigate(ROUTES.NO_ACCOUNT_FOUND, {
        state: { type: NoAccountFoundTypes.PHONE_NUMBER, phoneNumber },
      });
    }

    window.analytics?.identify(memberStatusByPhoneNumber?.uuid, {
      email: memberStatusByPhoneNumber?.email,
    });

    if (memberStatusByPhoneNumber?.status === MemberStatus.VERIFIED) {
      navigate(ROUTES.QRCODE_LOGIN, {
        state: { email: memberStatusByPhoneNumber?.email || "" },
      });
    }

    redirectToNoActivePlanIfAccountDisabled(memberStatusByPhoneNumber?.status);
  }, [memberStatusByPhoneNumber, isErrorFindingMemberByPhoneNumber]);

  const redirectToNoActivePlanIfAccountDisabled = (status?: number) => {
    if (status === MemberStatus.DISABLED && process.env.REACT_APP_PRICING_URL) {
      navigate(ROUTES.NO_ACTIVE_PLAN);
    }
  };

  const onSubmitHandler = async (contact_number?: string) => {
    const parsedPhoneNumber = parseInput(contact_number || phoneNumber);
    getMemberStatusByPhoneNumber(parsedPhoneNumber);
  };

  useEffect(() => {
    if (!member) return;
    setPhoneNumber(member.phone_number);

    onSubmitHandler(member.phone_number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  return (
    <>
      <TextWrapper>
        {member ? (
          <>
            <FinishedOnboardingTitle />
            <FinishedOnboardingDescription />
          </>
        ) : (
          <>
            <DesktopViewTitle type={state?.type} />
            <DesktopViewDescription type={state?.type} />
          </>
        )}
      </TextWrapper>
      <PhoneInputWrapper>
        <PhoneInputForm>
          <Input
            type="tel"
            value={{ value: phoneNumber }}
            onChange={setPhoneNumber}
          />
          <Button
            id="member_desktop-login_clicked"
            onClick={() => onSubmitHandler()}
          >
            Generate QR code
          </Button>
        </PhoneInputForm>
      </PhoneInputWrapper>
    </>
  );
};

export default PhoneNumberLogin;
