import React from "react";
import IllustrationPageLayout from "src/components/IllustrationPageLayout";
import illustration from "src/assets/images/join_session-illustration.webp";
import { Typography } from "src/components";
import { ScheduledSessionWrapper } from "../styles";
import { useGetMemberQuery } from "src/api/main";
import { useGetExpertQueryResolver } from "src/api/resolvers";
import Loading from "src/components/Loading";
import JoinSessionButton from "../components/JoinSessionButton";

const ScheduledSession = () => {
  const { data: member, isFetching: isMemberFetching } = useGetMemberQuery();
  const { data: expert, isFetching: isExpertFetching } =
    useGetExpertQueryResolver();

  if (isMemberFetching || isExpertFetching) {
    return <Loading />;
  }

  return (
    <IllustrationPageLayout
      TitleText={
        <>
          Hey <span>{member?.first_name}</span>, join your <span>session</span>
        </>
      }
      SubtitleText={
        <>
          Your video session with your relation expert,{" "}
          <span>{expert?.first_name}</span> is about to start.
        </>
      }
      illustration={illustration}
    >
      <ScheduledSessionWrapper>
        <Typography variant="body-medium">
          To connect to the session, just tap on the button
        </Typography>
        <JoinSessionButton />
      </ScheduledSessionWrapper>
    </IllustrationPageLayout>
  );
};

export default ScheduledSession;
