import { useNavigate } from "react-router-dom";
import { LayoutWithBackButton } from "src/components";
import useLocationState from "src/hooks/useLocationState";
import { ROUTES } from "src/types/routes";
import { Player } from "./components";
import { GrowthworkProps } from "./components/Player";
import Loading from "src/components/Loading";
import {
  useGetPartCompletedTasks,
  useGetPartGrowthworksResolver,
  useGetRemoveGwOrderFeatureQueryResolver,
} from "src/api/resolvers";
import { FeedbackTypes, GWPlatforms } from "src/api/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useUpdateRemoveGwOrderFeatureMutation } from "src/api/omnichat";
import { useGetMemberQuery } from "src/api/main";
import useGwFeedback from "src/hooks/useGwFeedback";

const VideoTask = () => {
  const growthwork = useLocationState<GrowthworkProps>("growthwork");
  const referer = useLocationState<string>("referer");
  const allGrowthWorks = useGetPartGrowthworksResolver();
  const wistiaGrowthworks = allGrowthWorks.filter(
    ({ platform, relation_cms_id }) =>
      platform === GWPlatforms.WISTIA &&
      relation_cms_id !== growthwork?.relation_cms_id
  );
  const { data: member } = useGetMemberQuery();
  const { data: completedTasks } = useGetPartCompletedTasks();
  const { data: isMemberDoneGWOutOfOrder } =
    useGetRemoveGwOrderFeatureQueryResolver();
  const { removeBlockersInTheCurrentPart } = useFlags();
  const [updateRemoveGwOrderFeature] = useUpdateRemoveGwOrderFeatureMutation();

  const navigate = useNavigate();

  const requestFeedback = useGwFeedback(FeedbackTypes.VIDEO);

  if (!growthwork) {
    return <Loading />;
  }

  const onComplete = () => {
    if (referer === ROUTES.JOURNEY) {
      return navigate(referer);
    }

    const isAudio = growthwork?.baselink.includes("async_audio");
    const isLastVideo = !wistiaGrowthworks.length
      ? growthwork.platform === GWPlatforms.WISTIA
      : wistiaGrowthworks.every((gw) => completedTasks?.[gw.relation_cms_id]);
    const props = !isAudio
      ? {
          state: { growthwork },
        }
      : undefined;

    if (
      (isAudio || !isLastVideo) &&
      member &&
      removeBlockersInTheCurrentPart &&
      !isMemberDoneGWOutOfOrder
    ) {
      updateRemoveGwOrderFeature({ uuid: member.uuid, status: true });
    }

    navigate(
      isAudio || !isLastVideo ? ROUTES.HOME : ROUTES.TASK_COMPLETED,
      props
    );

    requestFeedback({ gw_id: growthwork.relation_cms_id });
  };

  return (
    <LayoutWithBackButton onBack={() => referer && navigate(referer)}>
      <Player onComplete={onComplete} />
    </LayoutWithBackButton>
  );
};

export default VideoTask;
