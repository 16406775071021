import React, { useEffect } from "react";
import InfoLayout from "src/components/InfoLayout";
import illustration from "src/assets/images/error-illustration.svg";
import { Button } from "src/components";
import { useAuth0 } from "@auth0/auth0-react";
import LoginErrorFooter from "../components/SupportFooter/LoginErrorFooter";
import WaysToResolveList from "./components/WaysToResolveList";
import theme from "src/theme";
import { isMobile } from "src/utils/isMobile";
import { Link, useLocation } from "react-router-dom";
import { LoginTypes, NoAccountFoundTypes } from "src/types/login";
import { ROUTES } from "src/types/routes";
import { Typography } from "@mui/material";
import NewToRitual from "src/pages/NewLogin/NewToRitual";
import { SUBTITLE } from "src/constants/no_account_found";

const MemberDoesntExist = () => {
  const { logout, error } = useAuth0();
  const value = error?.message.split(":")[1];
  const { state } = useLocation() as {
    state: { type: NoAccountFoundTypes; email: string; phoneNumber: string };
  };

  const getSubtitle = () => {
    switch (state?.type) {
      case NoAccountFoundTypes.EMAIL:
        return SUBTITLE.email(state?.email || "");
      case NoAccountFoundTypes.PHONE_NUMBER:
        return SUBTITLE.phoneNumber(state?.phoneNumber || "");
      case NoAccountFoundTypes.DEFAULT:
        return SUBTITLE.default(value || "");
      default:
        return SUBTITLE.default(value || "");
    }
  };

  const PhoneNumberReferrer = () => (
    <Typography variant="h3" style={{ marginTop: "32px" }}>
      If you’re sure you are a member, check for typos and{" "}
      <Link
        style={{ color: theme.palette.primary.main }}
        to={ROUTES.DESKTOP_VIEW}
      >
        Try again{" "}
      </Link>
      or{" "}
      <Link
        to={ROUTES.DESKTOP_VIEW}
        state={{ type: LoginTypes.QRCODE_EMAIL }}
        style={{ color: theme.palette.primary.main }}
      >
        generate a QR code with your email address
      </Link>{" "}
      that you’ve used to signed up to OurRitual.
    </Typography>
  );

  useEffect(() => {
    document.body.style.backgroundColor = "white";

    return () => {
      document.body.style.backgroundColor = theme.palette.background.default;
    };
  }, []);

  return (
    <InfoLayout
      large
      title={
        <>
          <span>No account</span> found
        </>
      }
      subtitle={getSubtitle()}
      sx={{
        backgroundColor: "white",
        maxWidth: !isMobile() ? "680px" : "100%",
        margin: "auto",
        ".illustration": {
          maxWidth: !isMobile() ? "400px" : "224px",
        },
      }}
      AdditionalContent={
        !isMobile() && state?.type === NoAccountFoundTypes.PHONE_NUMBER ? (
          <PhoneNumberReferrer />
        ) : state?.type !== NoAccountFoundTypes.PHONE_NUMBER &&
          state?.type !== NoAccountFoundTypes.EMAIL ? (
          <>
            <WaysToResolveList />
            <Button
              id="go_back"
              className="action_button"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Go back to Log in
            </Button>
          </>
        ) : (
          <></>
        )
      }
      illustration={illustration}
    >
      {!isMobile() ? <NewToRitual /> : <LoginErrorFooter />}
    </InfoLayout>
  );
};

export default MemberDoesntExist;
