import { Banner } from "src/api/types";
import AddPartnerBanner from "./AddPartnerBanner";

enum Banners {
  APP_PARTNER_BANNER = 5,
}

export const customBanners: {
  [key: number]: ({ banner }: { banner: Banner }) => JSX.Element;
} = {
  [Banners.APP_PARTNER_BANNER]: AddPartnerBanner,
};
