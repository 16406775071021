import { SessionStatus } from "src/api/types";
import ScheduledSession from "./ScheduledSession";

import endedIllustration from "src/assets/images/session_ended-illustration.webp";
import canceledIllustration from "src/assets/images/session_canceled-illustration.webp";
import SessionNotStarted from "./SessionNotStarted";

export const pageContent = {
  [SessionStatus.ONGOING]: {
    Component: ScheduledSession,
  },

  [SessionStatus.ENDED]: {
    props: {
      TitleText: (
        <>
          Session has <span>already ended</span>
        </>
      ),
      SubtitleText:
        "Your video session with your relationship expert has already ended.\nDon’t forget to schedule a new session in the mobile app.",
      illustration: endedIllustration,
    },
  },

  [SessionStatus.SCHEDULED]: {
    Component: ScheduledSession,
  },

  [SessionStatus.CANCELED]: {
    props: {
      TitleText: (
        <>
          Session has been <span>cancelled</span>
        </>
      ),
      SubtitleText:
        "Your video session with your relationship expert has been cancelled.\nDon’t forget to schedule a new session in the mobile app.",
      illustration: canceledIllustration,
    },
  },
  [SessionStatus.RESCHEDULED]: {
    props: {
      TitleText: (
        <>
          Session has been <span>rescheduled</span>
        </>
      ),
      SubtitleText:
        "Your video session with your relationship expert has been rescheduled.",
      illustration: canceledIllustration,
    },
  },
  [SessionStatus.NOT_STARTED]: {
    Component: SessionNotStarted,
  },
};
