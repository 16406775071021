import { Typography } from "@mui/material";
import { Button } from "../Button";
import { useAuth0 } from "@auth0/auth0-react";
import InfoLayout from "../InfoLayout";
import { useGetErrorPageContentQuery } from "src/api/cms";

const ErrorLayout = ({subtitle}: {subtitle?: string}) => {
  const { logout } = useAuth0();
  const { data, isFetching } = useGetErrorPageContentQuery();

  return (
    <InfoLayout
      title={data?.title || ""}
      subtitle={subtitle || data?.subtitle || ""}
      isLoading={isFetching}
    >
      {data?.button && (
        <Button
          id="login-error-try-again"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          {data.button}
        </Button>
      )}
      <Typography
        variant="h3"
        fontWeight={700}
        sx={{ textAlign: "center", mt: "24px" }}
      >
        {data?.contact_text}
      </Typography>
    </InfoLayout>
  );
};

export default ErrorLayout;
