import { configureStore } from "@reduxjs/toolkit";
import { cmsApi } from "src/api/cms";
import { mainApi } from "src/api/main";
import { wistiaApi } from "src/api/wistia";
import { omnichatApi } from "src/api/omnichat";
import tokenReducer from "./tokenReducer";
import moduleRelationReducer from "./moduleRelationsReducer";
import drawersReducer from "./drawersReducer";
import supportChatReducer from "./supportReducer";
import libraryReducer from "./libraryReducer";
import feedbackReducer from "./feedbackReducer";

import { rtkQueryErrorLogger } from "./middlewares";

export const store = configureStore({
  reducer: {
    [cmsApi.reducerPath]: cmsApi.reducer,
    [mainApi.reducerPath]: mainApi.reducer,
    [wistiaApi.reducerPath]: wistiaApi.reducer,
    [omnichatApi.reducerPath]: omnichatApi.reducer,
    token: tokenReducer,
    moduleRelation: moduleRelationReducer,
    drawers: drawersReducer,
    supportChat: supportChatReducer,
    library: libraryReducer,
    feedback: feedbackReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      .concat(cmsApi.middleware)
      .concat(mainApi.middleware)
      .concat(wistiaApi.middleware)
      .concat(omnichatApi.middleware),
});
