import React from "react";
import IllustrationPageLayout from "src/components/IllustrationPageLayout";
import illustration from "src/assets/images/error-illustration.webp";
import ScheduleSession from "../ScheduleSession";

const InvalidSession = () => {
  return (
    <IllustrationPageLayout
      TitleText={
        <>
          Ooops. This link seems to be <span>invalid</span>
        </>
      }
      SubtitleText="We can’t find this page. If you need help, please contact our Support team."
      illustration={illustration}
    >
      <ScheduleSession />
    </IllustrationPageLayout>
  );
};

export default InvalidSession;
