import { useEffect } from "react";

const useCioInAppEvents = () => {
  const onMessageOpened = (name: string) => (event: any) => {
    window.analytics.track(`member_appmessage_${name}`, {
      messageId: event.detail.messageId,
    });
  };

  useEffect(() => {
    const setListener = () => {
      if (window._cio && window.analytics) {
        window._cio.on("in-app:message-dismissed", onMessageOpened("closed"));

        window._cio.on("in-app:message-opened", onMessageOpened("displayed"));

        window._cio.on("in-app:message-action", onMessageOpened("clicked"));
      } else {
        setTimeout(setListener, 100);
      }
    };

    setListener();
  }, []);
};

export default useCioInAppEvents;
