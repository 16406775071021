import CmsBanners from "./CmsBanners";
import { useGetBannersQuery } from "src/api/cms";
import PopUpNotifications from "../Notifications/PopUpNotifications/index";

const Banners = () => {
  const { data: banners } = useGetBannersQuery();

  if (!banners) return <PopUpNotifications />;

  return (
    <>
      <PopUpNotifications />
      <CmsBanners />
    </>
  );
};

export default Banners;
