import AWS from "aws-sdk";
import { useEffect } from "react";

const useAwsDownload = () => {
  useEffect(() => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    });
  }, []);

  const getLatestKey = (prefix: string): Promise<string> => {
    return new Promise((resolve) => {
      const s3 = new AWS.S3();
      s3.listObjectsV2(
        {
          Bucket: process.env.REACT_APP_AWS_BUCKET || "",
          Prefix: prefix,
        },
        (err, result) => {
          if (!result?.Contents) return resolve("");
          const key = result.Contents.sort((a, b) => {
            if (!a.LastModified || !b.LastModified) return 0;
            return b.LastModified?.getTime() - a.LastModified?.getTime();
          })[0]?.Key;
          resolve(key || "");
        }
      );
    });
  };

  const getData = (key: string): Promise<any> => {
    return new Promise((resolve) => {
      const s3 = new AWS.S3();
      s3.getObject(
        {
          Bucket: process.env.REACT_APP_AWS_BUCKET || "",
          Key: key,
        },
        function (error: any, data) {
          if (data?.Body) {
            const jsonString = String.fromCharCode.apply(
              null,
              data.Body as any
            );
            const response = JSON.parse(jsonString);
            resolve(response.data);
          }
        }
      );
    });
  };

  return { getLatestKey, getData };
};

export default useAwsDownload;
