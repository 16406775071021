import { useEffect } from "react";
import {
  useGetAssignedPartQueryResolver,
  useGetCompletedWelcomeSessionQueryResolver,
  useGetScheduledSessionQueryResolver,
} from "src/api/resolvers";

const useRefetchOnVisibleStateChange = () => {
  const { refetch: refetchAssignedPart } = useGetAssignedPartQueryResolver();
  const { refetch: refetchScheduledSession } =
    useGetScheduledSessionQueryResolver();
  const { refetch: refetchWelcomeSessionStatus } =
    useGetCompletedWelcomeSessionQueryResolver();

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        refetchAssignedPart();
        refetchScheduledSession();
        refetchWelcomeSessionStatus();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useRefetchOnVisibleStateChange;
