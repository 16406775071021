import React from "react";
import { Typography } from "src/components";
import { FooterWrapper } from "../styles";

const RITUALX_URL = process.env.REACT_APP_RITUALX_URL;

const ExpertFooter = () => {
  return (
    <FooterWrapper>
      <Typography variant="body-medium">
        Are you an OurRitual Expert?{" "}
        <a href={RITUALX_URL}>Join session as an Expert</a>
      </Typography>
    </FooterWrapper>
  );
};

export default ExpertFooter;
