import { useNavigate } from "react-router-dom";
import { useCompleteGrowthworkMutation, useGetMemberQuery } from "src/api/main";
import { FeedbackTypes, MemberGrowthworkUnion } from "src/api/types";
import { VideoAskEmbed } from "src/components";
import useLocationState from "src/hooks/useLocationState";
import { ROUTES } from "src/types/routes";
import { Wrapper } from "./styles";
import { useEffect } from "react";
import Loading from "src/components/Loading";
import useGwFeedback from "src/hooks/useGwFeedback";

const VideoAsk = () => {
  const growthwork = useLocationState<MemberGrowthworkUnion>("growthwork");
  const [completeGrowthwork] = useCompleteGrowthworkMutation();
  const navigate = useNavigate();
  const { data: member } = useGetMemberQuery();

  const requestFeedback = useGwFeedback(FeedbackTypes.VIDEO_JOURNAL);

  const onFinish = () => {
    if (!member || !growthwork) return;

    completeGrowthwork({
      member: member.uuid,
      growthworkId: growthwork.uuid,
    })
      .unwrap()
      .then(() => {
        requestFeedback({ gw_id: growthwork.relation_cms_id });
        navigate(ROUTES.TASK_COMPLETED, { state: { growthwork } });
      });
  };

  useEffect(() => {
    window.addEventListener("message", (message) => {
      if (message.data.type === "videoask_question_submitted") onFinish();
    });
    //eslint-disable-next-line
  }, []);

  if (!growthwork) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <VideoAskEmbed
        link={`${growthwork?.baselink}#contact_email=${member?.email}&hash=${
          growthwork?.url_hash
        }${process.env.REACT_APP_DEV_MODE ? "&funnel_mode=test" : ""}`}
      />
    </Wrapper>
  );
};

export default VideoAsk;
