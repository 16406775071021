import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import Login from "../Login";
import Loading from "src/components/Loading";
import NewLogin from "../NewLogin";

const Authorization = () => {
  const { showNewLogin } = useFlags();

  if (showNewLogin === undefined) return <Loading />;

  return showNewLogin ? <NewLogin /> : <Login />;
};

export default Authorization;
