import { styled } from "@mui/material";
import theme, { getResponsiveValue, DESKTOP_MOCKUP_HEIGHT } from "src/theme";

export const PageWrapper = styled("div")({
  paddingBottom: getResponsiveValue(100, DESKTOP_MOCKUP_HEIGHT),
  height: "100%",
});

export const Wrapper = styled("div")({
  width: "100%",
  maxWidth: "346px",

  marginTop: getResponsiveValue(32, DESKTOP_MOCKUP_HEIGHT),

  p: {
    marginTop: "16px",
  },
});

export const FooterWrapper = styled("div")({
  position: "fixed",
  bottom: 0,
  padding: getResponsiveValue(50, DESKTOP_MOCKUP_HEIGHT),

  width: "100%",
  background: "#F7F7FA",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  textAlign: "center",

  a: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: "underline",
  },

  p: {
    fontWeight: 400,
    color: theme.palette.grey.A200,
  },
});
