import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "src/types/routes";
import { useEffect, useMemo } from "react";
import Loading from "src/components/Loading";
import { useGetMemberQuery } from "src/api/main";
import useLocationState from "src/hooks/useLocationState";

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const { data: member } = useGetMemberQuery();
  const href = useLocationState<string>("href", true);

  const isRedirected = useMemo(
    () => window.location.pathname === ROUTES.SIGN_UP,
    []
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("invitee_email");
    member !== null &&
      loginWithRedirect({
        screen_hint: isRedirected ? "sign_up" : "login",
        email: email || "",
        redirectUri: href || window.location.origin,
        test_login: "true",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirected, loginWithRedirect, member]);

  return <Loading />;
};

export default Login;
