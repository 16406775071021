import { useNavigate, useLocation } from "react-router-dom";
import {
  CurrentPartStatuses,
  Growthwork,
  GWPlatforms,
  MemberModuleStatus,
} from "src/api/types";
import { ROUTES } from "src/types/routes";
import { Button } from "../Button";
import InfoLayout from "../InfoLayout";
import Confetti from "react-confetti";
import useLocationState from "src/hooks/useLocationState";
import {
  useGetActiveModuleQueryResolver,
  useGetPartCompletedTasks,
  useGetRemoveGwOrderFeatureQueryResolver,
  useGetTaskCompletedScreen,
} from "src/api/resolvers";
import { useGetMemberQuery, useUpdateModuleMutation } from "src/api/main";
import { useEffect, useMemo } from "react";
import Icon from "./components/Icon";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useUpdateRemoveGwOrderFeatureMutation } from "src/api/omnichat";

const TaskCompleted = ({ type }: { type?: GWPlatforms | "all" | "module" }) => {
  const growthwork = useLocationState<Growthwork>("growthwork", true);
  const { data: member } = useGetMemberQuery();
  const { data: module } = useGetActiveModuleQueryResolver();
  const { title, subtitle, button, icon, buttonId } = useGetTaskCompletedScreen(
    type || growthwork?.platform || "all"
  );
  const { data: isMemberDoneGWOutOfOrder } =
    useGetRemoveGwOrderFeatureQueryResolver();
  const { removeBlockersInTheCurrentPart } = useFlags();
  const [updateRemoveGwOrderFeature] = useUpdateRemoveGwOrderFeatureMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: completedTasks } = useGetPartCompletedTasks();

  const [updateModule] = useUpdateModuleMutation();

  const isAllTasksDone =
    completedTasks &&
    Object.values(completedTasks).every((value) => value === true);

  const detailsByPathname = {
    [ROUTES.ALL_TASKS_COMPLETED]: {
      body: { current_part_status: CurrentPartStatuses.WAITING_FOR_REVIEW },
      redirect: ROUTES.HOME,
    },
    [ROUTES.MODULE_COMPLETED]: {
      body: { selected: false, status: MemberModuleStatus.COMPLETED },
      redirect: ROUTES.MODULE_SELECTION,
    },
  };

  useEffect(() => {
    if (!member) return;
    if (removeBlockersInTheCurrentPart && !isMemberDoneGWOutOfOrder) {
      updateRemoveGwOrderFeature({ uuid: member.uuid, status: true });
    }
  }, [removeBlockersInTheCurrentPart, isMemberDoneGWOutOfOrder, member]);

  const handleClick = () => {
    if (
      location.pathname === ROUTES.ALL_TASKS_COMPLETED ||
      location.pathname === ROUTES.MODULE_COMPLETED
    ) {
      const { redirect, body } = detailsByPathname[location.pathname];
      updateModule({
        memberId: member?.uuid || "",
        moduleId: module?.program.cms_id || "",
        body,
      })
        .unwrap()
        .then(() => navigate(redirect));
    } else {
      isAllTasksDone
        ? navigate(ROUTES.ALL_TASKS_COMPLETED)
        : navigate(ROUTES.HOME);
    }
  };

  const isLoading = useMemo(() => !(title && subtitle), [title, subtitle]);

  return (
    <InfoLayout
      title={
        growthwork?.baselink.includes("async_audio")
          ? title.replace("watching", "listening")
          : title
      }
      subtitle={subtitle}
      Icon={Icon(icon)}
      isLoading={!!isLoading}
    >
      {button && (
        <Button id={buttonId} onClick={handleClick}>
          {button}
        </Button>
      )}
      <div>
        <Confetti opacity={0.5} numberOfPieces={75} gravity={0.05} />
      </div>
    </InfoLayout>
  );
};

export default TaskCompleted;
