import React from "react";
import {
  BillingDetails,
  Details,
  DetailsHeader,
  Illustration,
  ListItem,
  Price,
  Title,
  Wrapper,
} from "./styles";
import { Typography } from "src/components";

import { Tick } from "src/assets/icons";
import {
  useGetMemberSubscriptionStatusQueryResolver,
  useSubscriptionCmsDetails,
  useGetSubscriptionResolver, useGetMemberPlanResolver,
} from "src/api/resolvers";
import Loader from "./Loader";
import theme from "src/theme";
import illustration from "src/assets/images/subscription_Illustration.png";
import greyIllustration from "src/assets/images/subscription_Illustration_grey.png";
import { useFlags } from "launchdarkly-react-client-sdk";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";
import {BillingCycleType} from "src/api/types";

interface SubscriptionDetailsProps {
  isReactivate?: boolean;
}

const SubscriptionDetails = ({isReactivate=false}:SubscriptionDetailsProps) => {
  const { membershipPaused } = useFlags();
  const { data: details, isLoading: isCmsDetailsLoading } =
    useSubscriptionCmsDetails();
  const { data: subscription, isLoading: isSubscriptionLoading } =
    useGetSubscriptionResolver();
  const isMatchingCategory = useIsMatchingPlanCategory();
  const planDetails = useGetMemberPlanResolver();
  const {
    data: membershipStatus,
    isFetching: isMemberSubscriptionStatusFetching,
  } = useGetMemberSubscriptionStatusQueryResolver();
  const billingCycleMapping = {
  [BillingCycleType.DAY]: 'every 4 weeks',
  [BillingCycleType.MONTH]: 'per month',
  [BillingCycleType.YEAR]: "annually",
  [BillingCycleType.WEEK]: "weekly"
};
  if (isCmsDetailsLoading || isSubscriptionLoading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <DetailsHeader>
        <div>
          {!isMatchingCategory && <Typography
            color={
              membershipStatus?.isSubscriptionPaused && membershipPaused && !isReactivate
                ? theme.palette.grey[400]
                : theme.palette.primary.main
            }
            weight={600}
          >
            {details?.name}
          </Typography>}
          <Title variant="headline4">{details?.title}</Title>
          <Typography>
              {isMatchingCategory ? 'One-time purchase' : `${subscription?.duration} Month Membership`}
          </Typography>
          <BillingDetails>
            {subscription?.started_date && (
              <Typography variant="caption">
                Started on {subscription?.started_date}
              </Typography>
            )}
            {membershipPaused &&
            membershipStatus?.isSubscriptionPaused &&
            membershipStatus?.pauseUntilDate ? (
              <Typography variant="caption">
                Paused until <span>{membershipStatus?.pauseUntilDate}</span>
              </Typography>
            ) : (
              !isMemberSubscriptionStatusFetching &&
              subscription?.next_billing_date && !membershipStatus?.isSubscriptionPaused &&
              !isMatchingCategory &&(
                <Typography variant="caption">
                  Next billing on <span>{subscription?.next_billing_date}</span>
                </Typography>
              )
            )}
          </BillingDetails>
        </div>
        <Illustration
          src={
            membershipStatus?.isSubscriptionPaused && membershipPaused && !isReactivate
              ? greyIllustration
              : illustration
          }
        />
      </DetailsHeader>
      <Details>
        <Typography weight={600}>Your plan includes: </Typography>
        <div>
          {details?.items_included.map((item) => (
            <ListItem key={item}>
              <Tick
                stroke={
                  membershipStatus?.isSubscriptionPaused && membershipPaused && !isReactivate
                    ? theme.palette.grey[500]
                    : undefined
                }
              />
              <Typography weight={400}>{item}</Typography>
            </ListItem>
          ))}
        </div>
      </Details>
      {subscription?.price !== 0 && !isMatchingCategory && (
        <Price>
          <Typography variant="headline3">${subscription?.price}</Typography>
          <Typography variant="caption" color={theme.palette.grey[100]}>
            {planDetails?.billing_cycle_type ? billingCycleMapping[planDetails?.billing_cycle_type] : 'per month'}
          </Typography>
        </Price>
      )}
    </Wrapper>
  );
};

export default SubscriptionDetails;
