import React, { useEffect, useState } from "react";
import { useGetMemberQuery } from "src/api/main";
import IllustrationPageLayout from "src/components/IllustrationPageLayout";
import Loading from "src/components/Loading";
import illustration from "src/assets/images/join_session-illustration.webp";
import Countdown from "./Countdown";
import { useGetSessionDetailsQueryResolver } from "src/api/resolvers";
import {
  SESSION_START_GAP,
  formatSessionDate,
  getTimeUntilSession,
  isSessionInMoreThanAnHour,
} from "./utils";
import JoinSessionButton from "../../components/JoinSessionButton";
import { Typography } from "src/components";
import { NotStartedWrapper } from "./styles";

const SessionNotStarted = () => {
  const { data: member, isFetching } = useGetMemberQuery();
  const { data: session } = useGetSessionDetailsQueryResolver();
  const [joinDisabled, setJoinDisabled] = useState(true);

  const moreThanHour = isSessionInMoreThanAnHour(session?.next_session!);

  useEffect(() => {
    if (!session) return;

    const timeLeft =
      getTimeUntilSession(session.next_session) - SESSION_START_GAP;

    const timeout = setTimeout(() => {
      setJoinDisabled(false);
    }, timeLeft);

    return () => {
      clearTimeout(timeout);
    };
  }, [session]);

  if (isFetching) {
    return <Loading />;
  }

  const { sessionDate, sessionTime } = formatSessionDate(
    session?.next_session!
  );

  return (
    <IllustrationPageLayout
      TitleText={
        <>
          Hey{member?.first_name ? ` ${member.first_name}` : ""}{", "}
          <span>
            {moreThanHour
              ? "your session is not far away"
              : "your session will begin shortly"}
          </span>
        </>
      }
      SubtitleText={
        <>
          Your video session with your relationship expert will start{" "}
          {moreThanHour ? (
            <>
              on <span>{sessionDate}</span> at <span>{sessionTime}</span>
            </>
          ) : (
            "in"
          )}
        </>
      }
      illustration={illustration}
    >
      <NotStartedWrapper>
        {!moreThanHour && <Countdown />}
        <JoinSessionButton disabled={joinDisabled} />
        <Typography variant="body2">
          The 'Join session' button will become active when it's time for the
          meeting to start.
        </Typography>
      </NotStartedWrapper>
    </IllustrationPageLayout>
  );
};

export default SessionNotStarted;
