import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { mainApiUrls } from "./urls";
import {
  Account,
  AddPartnerBody,
  AssignedPart,
  CurrentPartStatuses,
  Expert,
  FeedbackPostParams,
  FirstPartCompleted,
  GWPlatforms,
  JournalEntryResult,
  LibraryGrowthworkParts,
  MatchingLink,
  Member,
  MemberFeedback,
  MemberGrowthWork,
  MemberGrowthWorkStatus,
  MemberModule,
  MemberPlanDetails,
  ModuleMatching,
  OnboardingStatus,
  PathwayRecommendation,
  Session,
  SessionLink,
  Subscription,
  SubscriptionStatusResponse,
  SurveyResponse,
} from "./types";
import {
  transformSession,
  transformSessionDetails,
} from "./utils/responseTransformers/sessionTransformer";
import { transformSubscription } from "./utils/responseTransformers/subscriptionResponseTransformer";
import {
  transformMember,
  transformUser,
} from "./utils/responseTransformers/userTransformer";
import { updateLibraryGrowthworksQuery } from "./utils/queryUpdaters/updateLibraryGrowthworks";
import dayjs from "dayjs";
import { ChatMember } from "src/types/chat";
import { useSelector } from "react-redux";

export const mainApi = createApi({
  reducerPath: "main",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as any).token.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("x-ritual-source", "webapp");

      return headers;
    },
  }),
  tagTypes: [
    "Modules",
    "MemberGrowthworks",
    "ModuleMatching",
    "AssignedPart",
    "Member",
    "Feedback",
    "SubscriptionStatus",
  ],
  endpoints: (builder) => ({
    getMember: builder.query<Member | null, void>({
      query: mainApiUrls.members,
      transformResponse: (res: Member[]) => {
        const member = res[0];
        if (!member) return null;
        return {
          ...transformMember(member),
          partner: member.partner && transformMember(member.partner),
        };
      },
      providesTags: ["Member"],
    }),
    updateModule: builder.mutation<
      MemberModule,
      { memberId: string; moduleId: string; body: Partial<MemberModule> }
    >({
      query: ({ memberId, moduleId, body }) => ({
        url: mainApiUrls.updateModule(memberId, moduleId),
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Modules", "AssignedPart"],
    }),
    getMemberModules: builder.query<MemberModule[], string>({
      query: mainApiUrls.modules,
      providesTags: ["Modules"],
    }),

    createMemberGrowthwork: builder.mutation<
      MemberGrowthWork | undefined,
      {
        expertId: string;
        memberId: string;
        cms_id: string;
        module_relation_cms: {
          id: number;
          gw_link_platform: GWPlatforms;
          gw_link_url: string;
        }[];
      }
    >({
      query: ({ expertId, memberId, module_relation_cms }) => ({
        url: mainApiUrls.memberGrowth(memberId),
        method: "POST",
        body: {
          expert: expertId,
          module_relation_cms,
        },
      }),
      /* eslint-disable no-shadow-restricted-names */
      transformResponse: (res: MemberGrowthWork[], undefined, { cms_id }) =>
        res.find(({ gw_cms_id }) => gw_cms_id === cms_id),
    }),

    completeGrowthwork: builder.mutation<
      MemberGrowthWork,
      { member: string; growthworkId: string; status?: MemberGrowthWorkStatus }
    >({
      query: ({ member, growthworkId, status }) => ({
        url: mainApiUrls.memberGrowthById({
          memberId: member,
          growthworkId,
        }),
        method: "PATCH",
        body: {
          member,
          status: status || MemberGrowthWorkStatus.COMPLETED,
        },
      }),
      invalidatesTags: ["MemberGrowthworks"],
      onQueryStarted: (api, args): Promise<void> =>
        updateLibraryGrowthworksQuery(mainApi)(api, args),
    }),

    getMemberGrowthworks: builder.query<
      MemberGrowthWork,
      {
        memberId: string;
        growthworkId: string;
      }
    >({
      query: mainApiUrls.memberGrowthById,
    }),

    getMatchingModules: builder.query<
      ModuleMatching[],
      {
        memberId: string;
        modules_list: string;
      }
    >({
      query: mainApiUrls.matchingModules,
    }),

    getCompletedCount: builder.query<
      number,
      { memberId: string; gwList: string }
    >({
      query: mainApiUrls.completedGrowthworksCount,
      transformResponse: (res: any) => res.count,
      providesTags: ["MemberGrowthworks"],
    }),

    getCompletedTasks: builder.query<
      { [key: string]: boolean },
      { memberId: string; gwList: string }
    >({
      query: mainApiUrls.completedGrowthworksTasks,
      providesTags: ["MemberGrowthworks"],
    }),

    getScheduledSession: builder.query<Session[], string>({
      query: mainApiUrls.scheduledSession,
      transformResponse: (res: Session[]) =>
        res
          .map(transformSession)
          .sort(
            (a, b) =>
              new Date(a.next_session).getTime() -
              new Date(b.next_session).getTime()
          ),
    }),

    getExpert: builder.query<Expert, string>({
      query: mainApiUrls.expert,
      transformResponse: transformUser,
    }),

    getAssignedPart: builder.query<AssignedPart, string>({
      query: mainApiUrls.assignedPart,
      providesTags: ["AssignedPart"],
    }),

    getCompletedWelcomeSession: builder.query<boolean, string>({
      query: mainApiUrls.welcomeSessionCompleted,
    }),

    getCompletedMatchingSession: builder.query<MatchingLink, string>({
      query: mainApiUrls.matchingSessionCompleted,
    }),

    getMemberPlan: builder.query<MemberPlanDetails, string>({
      query: mainApiUrls.memberPlanDetails,
    }),

    getMemberGrowthworksCmsId: builder.query<string[], string>({
      query: mainApiUrls.memberGrowthworksCmsIds,
    }),

    updateMember: builder.mutation<
      Member,
      { memberId: string; body: Partial<Member> }
    >({
      query: ({ memberId, body }) => ({
        url: mainApiUrls.member(memberId),
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Member"],
    }),

    getSessionLinks: builder.query<SessionLink[], string>({
      query: mainApiUrls.sessionLinks,
    }),

    getOnboardingStatus: builder.query<OnboardingStatus, string>({
      query: mainApiUrls.onboardingStatus,
    }),

    addPartner: builder.mutation<
      any,
      { memberId: string; body: AddPartnerBody }
    >({
      query: ({ memberId, body }) => ({
        url: mainApiUrls.addPartner(memberId),
        method: "POST",
        body,
      }),
    }),

    sendCompleteOnboardingEvent: builder.mutation<
      any,
      { memberId: string; properties: Object }
    >({
      query: ({ memberId, properties }) => ({
        url: mainApiUrls.completeOnboardingEvent(memberId),
        method: "POST",
        body: properties,
      }),
    }),

    getStreamToken: builder.query<{ token: string }, string>({
      query: mainApiUrls.streamToken,
    }),

    getFrontHash: builder.query<{ hash: string }, string>({
      query: mainApiUrls.frontHash,
    }),

    getSessionDetails: builder.query<Session, string>({
      query: mainApiUrls.publicSession,
      transformResponse: transformSessionDetails,
    }),

    sessionShowEvent: builder.mutation<void, string>({
      query: (params) => ({
        url: mainApiUrls.publicSession(params),
        method: "PATCH",
      }),
    }),

    subscriptionReactivate: builder.mutation<void, string>({
      query: (memberId) => ({
        url: mainApiUrls.subscriptionReset(memberId),
        method: "PATCH",
      }),
      invalidatesTags: ["SubscriptionStatus"],
    }),

    subscriptionReactivateEvent: builder.mutation<void, string>({
      query: (memberId) => ({
        url: mainApiUrls.subscriptionResetEvent(memberId),
        method: "PATCH",
      }),
    }),

    getSubscription: builder.query<Subscription, string>({
      query: mainApiUrls.subscription,
      transformResponse: transformSubscription,
    }),

    getMemberPlanDetails: builder.query<Subscription, string>({
      query: mainApiUrls.memberPlanDetails,
      transformResponse: (res: MemberPlanDetails) => {
        return transformSubscription({ ...res, started_date: res.started_at });
      },
    }),

    getMemberAccountDetails: builder.query<Account, string>({
      query: mainApiUrls.account,
    }),

    getMemberSubscriptionStatus: builder.query<
      { isSubscriptionPaused: boolean; pauseUntilDate: string | null },
      string
    >({
      query: mainApiUrls.subscriptionStatus,
      providesTags: ["SubscriptionStatus"],
      transformResponse: ({
        is_subscription_paused,
        pause_until_date,
      }: SubscriptionStatusResponse) => ({
        isSubscriptionPaused: is_subscription_paused,
        pauseUntilDate: pause_until_date
          ? dayjs(pause_until_date * 1000).format("MMM DD, YYYY")
          : null,
      }),
    }),

    updateMemberPart: builder.mutation<
      unknown,
      { memberId: string; partId: string; status: CurrentPartStatuses }
    >({
      query: (params) => ({
        url: mainApiUrls.memberPart(params),
        method: "PATCH",
        body: {
          status: params.status,
        },
      }),
      invalidatesTags: ["AssignedPart"],
    }),

    getLibraryGrowthworks: builder.query<
      LibraryGrowthworkParts[],
      { memberId: string; pathwayId: string }
    >({
      query: mainApiUrls.videoTask,
    }),

    getOrCreateChatMembers: builder.mutation<string[], ChatMember[]>({
      query: (body) => ({
        url: mainApiUrls.getChatMembers,
        method: "POST",
        body,
      }),
    }),

    getMemberStatusByPhoneNumber: builder.query<
      { status: number; uuid: string; email: string },
      string
    >({
      query: mainApiUrls.getMemberStatusByPhoneNumber,
    }),

    getMemberStatusByEmail: builder.query<
      { status: number; uuid: string; email: string },
      string
    >({
      query: mainApiUrls.getMemberStatusByEmail,
    }),

    requestAccessLink: builder.mutation<string, { uuid: string }>({
      query: ({ uuid }) => ({
        url: mainApiUrls.requestAccessLink(uuid),
        method: "POST",
      }),
    }),

    sendInAppFeedback: builder.mutation<MemberFeedback, FeedbackPostParams>({
      query: ({ member, ...rest }) => ({
        url: mainApiUrls.memberFeedback(member),
        method: "POST",
        body: {
          member,
          ...rest,
        },
      }),
      invalidatesTags: ["Feedback"],
    }),

    getMemberFeedbacks: builder.query<MemberFeedback[], string>({
      query: mainApiUrls.memberFeedback,
      providesTags: ["Feedback"],
    }),

    getCompletedSessionsCount: builder.query<number, string>({
      query: mainApiUrls.completedSessionsCount,
    }),

    getSurvey: builder.query<
      SurveyResponse | undefined,
      { uuid: string; surveyId: string }
    >({
      query: mainApiUrls.getSurvey,
      transformResponse: (survey: SurveyResponse[]) =>
        survey.length > 0 ? survey[0] : undefined,
    }),

    sendClickSurveyEvent: builder.mutation<
      undefined,
      { uuid: string; surveyId: string }
    >({
      query: (arg) => ({
        url: mainApiUrls.surveyClickEvent(arg),
        method: "POST",
        body: {},
      }),
    }),

    getGrowthworkDetails: builder.query<
      MemberGrowthWork,
      { member_id: string; cms_id: number }
    >({
      query: mainApiUrls.growthworkDetails,
    }),

    getJournalResult: builder.query<JournalEntryResult, string>({
      query: mainApiUrls.journalResult,
    }),

    getRecommendations: builder.query<
      PathwayRecommendation,
      { memberId: string; isDefault?: boolean }
    >({
      query: mainApiUrls.recommendations,
    }),
  }),
});

/* eslint-disable no-empty-pattern */
export const {
  useGetMatchingModulesQuery,
  useUpdateModuleMutation,
  useGetMemberModulesQuery,
  useCreateMemberGrowthworkMutation,
  useCompleteGrowthworkMutation,
  useGetMemberGrowthworksQuery,
  useGetCompletedCountQuery,
  useGetCompletedTasksQuery,
  useGetScheduledSessionQuery,
  useGetExpertQuery,
  useGetAssignedPartQuery,
  useGetCompletedWelcomeSessionQuery,
  useGetCompletedMatchingSessionQuery,
  useGetMemberPlanQuery,
  useGetMemberGrowthworksCmsIdQuery,
  useUpdateMemberMutation,
  useGetSessionLinksQuery,
  useGetOnboardingStatusQuery,
  useAddPartnerMutation,
  useSendCompleteOnboardingEventMutation,
  useGetStreamTokenQuery,
  useGetFrontHashQuery,
  useGetSessionDetailsQuery,
  useSessionShowEventMutation,
  useSubscriptionReactivateMutation,
  useSubscriptionReactivateEventMutation,
  useGetSubscriptionQuery,
  useGetMemberPlanDetailsQuery,
  useUpdateMemberPartMutation,
  useGetLibraryGrowthworksQuery,
  useGetMemberSubscriptionStatusQuery,
  useLazyGetMemberStatusByPhoneNumberQuery,
  useLazyGetMemberStatusByEmailQuery,
  useGetSurveyQuery,
  useGetOrCreateChatMembersMutation,
  useRequestAccessLinkMutation,
  useSendInAppFeedbackMutation,
  useGetMemberFeedbacksQuery,
  useGetMemberAccountDetailsQuery,
  useGetCompletedSessionsCountQuery,
  useSendClickSurveyEventMutation,
  useGetGrowthworkDetailsQuery,
  useGetJournalResultQuery,
  useGetRecommendationsQuery,
} = mainApi;

const { useGetMemberQuery: useMemberQuery } = mainApi;

export const useGetMemberQuery = () => {
  const { token } = useSelector(
    (state: { token: { token?: string } }) => state.token
  );
  return useMemberQuery(undefined, { skip: !token });
};
