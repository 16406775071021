import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetPathwaySelectionScreenQuery } from "src/api/cms";
import { Module } from "src/api/types";
import { LeftArrow } from "src/assets/icons";
import useLocationState from "src/hooks/useLocationState";
import { ROUTES } from "src/types/routes";
import { Wrapper, Title, Icon, Illustration } from "./styles";

const Header = () => {
  const pathway = useLocationState<Module>("pathway");
  const { data: cmsContent } = useGetPathwaySelectionScreenQuery();
  const navigate = useNavigate();

  const onBackClick = () => {
    navigate(ROUTES.MODULE_SELECTION);
  };

  if (!pathway || !cmsContent) return <></>;

  return (
    <Wrapper>
      <LeftArrow
        id="back-button"
        onClick={onBackClick}
        style={{ alignSelf: "flex-start", zIndex: 10 }}
      />
      <Icon src={pathway.icon} />
      <Title variant="h1">{pathway.name}</Title>
      <Illustration src={cmsContent?.illustration} />
    </Wrapper>
  );
};

export default Header;
