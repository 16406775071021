import { styled, Typography } from "@mui/material";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "32px 48px 0 48px",
  alignItems: "center",
});

export const Title = styled(Typography)({
  fontWeight: 700,
  marginTop: "32px",
  textAlign: "center",
});

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[100],
  textAlign: "center",
  whiteSpace: "break-spaces",
  marginTop: "24px",
  marginBottom: "16px",
}));

export const ButtonsWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  width: "100%",

  button: {
    height: "100%",
  },
});
