import { useGetPathwaySelectionScreenQuery } from "src/api/cms";
import { useGetMemberQuery } from "src/api/main";
import { LayoutWithBackButton } from "src/components";
import { PathwaysList } from "./components";
import { Title, Subtitle } from "./styles";
import Loading from "src/components/Loading";
import useGetPathways from "./utils/useGetPathways";

const ModuleSelection = () => {
  const { data: member, isFetching: isMemberFetching } = useGetMemberQuery();
  const { data: cmsContent, isFetching: isCmsFetching } =
    useGetPathwaySelectionScreenQuery();
  const { pathways, isLoading } = useGetPathways();

  if (isLoading || isMemberFetching || isCmsFetching) return <Loading />;

  return (
    <LayoutWithBackButton>
      <Title>
        {cmsContent?.title.replace("{first_name}", member?.first_name || "")}
      </Title>
      <Subtitle>{cmsContent?.subtitle}</Subtitle>
      <PathwaysList pathways={pathways || []} />
    </LayoutWithBackButton>
  );
};

export default ModuleSelection;
