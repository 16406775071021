import { styled } from "@mui/material";

export const Wrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "relative",

  "> svg": {
    top: "24px",
    right: "24px",
    position: "absolute",
  },

  h2: {
    fontSize: "22px",
    lineHeight: "28px",
  },

  h3: {
    fontSize: "14px",
    lineHeight: "18px",
    color: theme.palette.grey[600],
  },
}));

export const AudioWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "15px",
  width: "100%",
});
