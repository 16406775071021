import { styled } from "@mui/material";
import theme from "src/theme";

export const Wrapper = styled("div")({
  marginTop: "16px",
  width: "100%",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  maxWidth: "350px",
});

export const Timer = styled("div")({
  padding: "8px 24px",
  borderRadius: "12px",
  border: `1px solid ${(theme as any).palette.info[100]}`,
  background: theme.palette.grey.A700,

  color: theme.palette.primary.main,
  textAlign: "center",
  fontFamily: "Avenir Next",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "36px",
  maxWidth: "fit-content",
});

export const NotStartedWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  
  button: {
    marginTop: "40px",
    maxWidth: "346px",
  },

  p: {
    marginTop: "24px",
    fontWeight: 400,
    color: theme.palette.grey[400],
  },
});
