import React from "react";
import { Button } from "src/components";
import useJoinSession from "src/hooks/useJoinSession";

const JoinSessionButton = ({ disabled = false }: { disabled?: boolean }) => {
  const { joinSession } = useJoinSession();

  return (
    <Button id="join-session" onClick={() => joinSession()} disabled={disabled}>
      Join session
    </Button>
  );
};

export default JoinSessionButton;
