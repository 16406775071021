import { useEffect, useState } from "react";
import { useGetMemberQuery } from "src/api/main";
import { useUpdateLastActivityTimeMutation } from "src/api/omnichat";

const DEBOUNCE_TIME = 60000; // 1 min

const useUpdateLastActive = () => {
  const [lastActiveTime, setLastActiveTime] = useState<number>(0);
  const { data: member } = useGetMemberQuery();
  const [updateLastActiveTime] = useUpdateLastActivityTimeMutation();

  useEffect(() => {
    if (!member) return;

    const handleClick = () => {
      const currentTime = new Date().getTime();
      if (currentTime - lastActiveTime > DEBOUNCE_TIME) {
        setLastActiveTime(new Date().getTime());
        updateLastActiveTime({ uuid: member.uuid });
      }
    };

    window.addEventListener("click", handleClick);
    window.addEventListener("touchend", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
      window.removeEventListener("touchend", handleClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActiveTime, member]);
};

export default useUpdateLastActive;
