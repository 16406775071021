import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { RouteObjectExtended } from "src/types/routes";
import { ROUTES, allowedOnDesktopRoutes } from "src/types/routes";
import { useGetTokenState } from "src/store/selectors";
import LoginErrorScreen from "./LoginErrorScreen";
import { isMobile } from "src/utils/isMobile";
import { MemberLoader } from "src/components";

export enum RouteTypes {
  PUBLIC = "public",
  PRIVATE = "private",
}

const RouteGuardComponent = ({
  children,
  type,
  path,
  redirect,
}: {
  children: ReactNode;
  type: RouteTypes;
  path: string;
  redirect?: string;
}) => {
  const { isAuthenticated, isLoading, error } = useAuth0();
  const { token: storedToken } = useGetTokenState();

  const isAllowedOnDesktop = allowedOnDesktopRoutes.some(
    (route) => path === route
  );

  if (!isMobile() && !process.env.REACT_APP_E2ETests && !isAllowedOnDesktop)
    return (
      <Navigate
        to={ROUTES.DESKTOP_VIEW}
        state={{ href: window.location.href }}
      />
    );

  if (error) return <LoginErrorScreen error={error} />;

  if (isLoading || (isAuthenticated && !storedToken)) return <MemberLoader />;

  const routeProps = {
    [RouteTypes.PRIVATE]: {
      condition: isAuthenticated && storedToken,
      redirect: redirect || ROUTES.LOGIN,
    },
    [RouteTypes.PUBLIC]: {
      condition: !isAuthenticated,
      redirect: redirect || ROUTES.HOME,
    },
  };

  return (
    <>
      {routeProps[type].condition ? (
        children
      ) : (
        <Navigate
          to={routeProps[type].redirect + window.location.search}
          state={{
            href:
              window.location.origin +
              ROUTES.LOGIN_REDIRECT +
              `?path=${window.location.pathname}`,
          }}
        />
      )}
    </>
  );
};

const RouteGuard =
  (type: RouteTypes) =>
  ({ path, element, redirect }: RouteObjectExtended) => ({
    path,
    element: (
      <RouteGuardComponent type={type} path={path || ""} redirect={redirect}>
        {element}
      </RouteGuardComponent>
    ),
  });

export default RouteGuard;
