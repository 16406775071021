import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { cmsUrls } from "./urls";
import {
  Module,
  CMSResponseType,
  Part,
  ModuleRelation,
  Growthwork,
  YouareOnYourWay,
  TaskCompletedScreenTransformed,
  TaskCompletedScreenCMS,
  ErrorPageContent,
  Banner,
  Drawer,
  MemberFunnels,
  CarouselContent,
  ModulePartGrowthworks,
  WistiaTaskPage,
  GrowthworkLabel,
  ContactUsContent,
  ExpertCms,
  ExpertCmsResponse,
  ScheduleSessionDetails,
  PathwaySelectionScreen,
  RescheduleSessionRestriction,
  SessionEvent,
  ChatPage,
  SubscriptionDetails,
  SubscriptionDetailsCms,
} from "./types";
import {
  getModulePartGrowthworks,
  sortModuleRelation,
  transformImage,
} from "./utils";
import { getPartGrowthworks } from "./utils/responseTransformers/partGrowthworksResponseTransformer";

const transformCmsResponse = (res: CMSResponseType<any, true>) => {
  return res.data.map(({ attributes, id }) => ({ ...attributes, id }));
};

const cmsUrl = process.env.REACT_APP_CMS_URL || "";

export const cmsApi = createApi({
  reducerPath: "cms",
  baseQuery: fetchBaseQuery({
    baseUrl: cmsUrl,
  }),
  endpoints: (builder) => ({
    getModules: builder.query<Module[], void>({
      query: cmsUrls.modules,
      transformResponse: (res: CMSResponseType<Module, true>) => {
        return transformCmsResponse(res).map((item) => ({
          ...item,
          module_id: String(item.id),
          icon: transformImage(item.icon),
          teaser: item.teaser?.data?.attributes.link || "",
        }));
      },
    }),

    getModuleRelation: builder.query<ModuleRelation[], string | undefined>({
      query: cmsUrls.moduleRelation,
      transformResponse: (res: CMSResponseType<ModuleRelation, true>) => {
        const total = res.meta.pagination.total;
        return transformCmsResponse(res).map((item) => ({
          ...item,
          total,
          module: {
            data: {
              id: item.module.data.id,
              attributes: {
                ...item.module.data.attributes,
                module_id: String(item.module.data.id),
              },
            },
          },
        }));
      },
    }),

    getCurrentPart: builder.query<
      Part,
      {
        partOrder: string;
        moduleId: string;
      }
    >({
      query: cmsUrls.currentPart,
      transformResponse: (res: CMSResponseType<ModuleRelation, true>) => {
        const part = transformCmsResponse(res)[0]?.part;
        if (!part) return null;
        return { ...part.data.attributes, part_id: part.data.id };
      },
    }),

    getPartGrowthworks: builder.query<
      Growthwork[],
      { partId: string; expertId: string; moduleId: string }
    >({
      query: cmsUrls.getPartGrowthworks,
      transformResponse: (
        res: CMSResponseType<ModuleRelation, true>,
        _,
        { expertId }
      ) => {
        const growthworks = transformCmsResponse(res);
        return getPartGrowthworks(growthworks, expertId)
          .sort((a, b) => a.gw_order - b.gw_order)
          .map((item) => {
            const gwAttributes = item?.gw.data.attributes;
            const image: any = gwAttributes.image;

            return {
              ...gwAttributes,
              gw_order: item.gw_order,
              relation_cms_id: item?.id,
              image: image ? transformImage(image) : "",
            };
          });
      },
    }),

    getParts: builder.query<Part[], void>({
      query: cmsUrls.parts,
      transformResponse: (res: CMSResponseType<Part, true>) => {
        return transformCmsResponse(res).map((item) => ({
          ...item,
          module: String(item.module?.data?.id),
          part_id: item.id,
        }));
      },
    }),

    getYoureOnYourWay: builder.query<YouareOnYourWay, number>({
      query: cmsUrls.youreOnYourWay,
      transformResponse: (res: CMSResponseType<YouareOnYourWay, true>) => {
        return transformCmsResponse(res)[0];
      },
    }),

    getTaskCompletedScreens: builder.query<
      TaskCompletedScreenTransformed[],
      void
    >({
      query: cmsUrls.taskCompletedScreens,
      transformResponse: (res: CMSResponseType<TaskCompletedScreenCMS, true>) =>
        transformCmsResponse(res).map((item: TaskCompletedScreenCMS) => {
          const origin = new URL(cmsUrl).origin;
          const icon = origin + item.icon.data[0].attributes.url;
          const subtitle = item.subtitle.replaceAll("\\n", "\n");
          return { ...item, icon, subtitle };
        }),
    }),

    getErrorPageContent: builder.query<ErrorPageContent, void>({
      query: cmsUrls.errorPage,
      transformResponse: (res: CMSResponseType<ErrorPageContent, false>) =>
        res.data.attributes,
    }),

    getErrorLoginPaymentPageContent: builder.query<ErrorPageContent, void>({
      query: cmsUrls.errorLoginPaymentPage,
      transformResponse: (res: CMSResponseType<ErrorPageContent, false>) =>
        res.data.attributes,
    }),

    getWelcomeSessionLink: builder.query<string, MemberFunnels>({
      query: cmsUrls.welcomeSession,
      transformResponse: (res: CMSResponseType<{ link: string }, true>) => {
        if (process.env.REACT_APP_DEV_MODE)
          return "https://calendly.com/ritual-schedule/dev-ritual-welcome-session";
        else return res.data?.[0].attributes.link || "";
      },
    }),

    getBanners: builder.query<Banner[], void>({
      query: cmsUrls.banners,
      transformResponse: (res: CMSResponseType<Banner, true>) =>
        transformCmsResponse(res).map((item) => ({
          ...item,
          icon: new URL(cmsUrl).origin + item.icon.data.attributes.url,
          query_param: item?.drawer.data?.attributes.query_param || "",
        })),
    }),

    getDrawers: builder.query<Drawer[], void>({
      query: cmsUrls.drawers,
      transformResponse: (res: CMSResponseType<Drawer, true>) =>
        transformCmsResponse(res).map((item) => ({
          ...item,
          icon: new URL(cmsUrl).origin + item.icon.data.attributes.url,
        })),
    }),

    getCarouselPage: builder.query<CarouselContent[], void>({
      query: cmsUrls.carouselPage,
      transformResponse: (res: CMSResponseType<CarouselContent, true>) =>
        transformCmsResponse(res)
          .map((item) => ({
            ...item,
            image: new URL(cmsUrl).origin + item.image.data.attributes.url,
          }))
          .sort((a, b) => a.step - b.step),
    }),

    getVideoGrowthWorks: builder.query<ModulePartGrowthworks, string>({
      query: cmsUrls.videoGrowthworks,
      transformResponse: (res: CMSResponseType<ModuleRelation, true>) => {
        const response = transformCmsResponse(res).sort(sortModuleRelation);
        return getModulePartGrowthworks(response);
      },
    }),

    getWistiaTaskPage: builder.query<WistiaTaskPage, void>({
      query: cmsUrls.wistiaTaskPage,
      transformResponse: (res: CMSResponseType<WistiaTaskPage, false>) =>
        res.data.attributes,
    }),

    getGrowthworkLabels: builder.query<GrowthworkLabel[], void>({
      query: cmsUrls.growthworkLabels,
      transformResponse: (res: CMSResponseType<GrowthworkLabel, true>) =>
        transformCmsResponse(res).map((item) => ({
          ...item,
          icon: transformImage(item.icon),
        })),
    }),
    getContactUsContent: builder.query<ContactUsContent, void>({
      query: cmsUrls.contactUsContent,
      transformResponse: (res: CMSResponseType<any, false>) => {
        const item = res.data.attributes;
        return {
          ...item,
          topics: item.topics.split("\n"),
          image: transformImage(item.image),
        };
      },
    }),

    getPathwaySelectionScreen: builder.query<PathwaySelectionScreen, void>({
      query: cmsUrls.pathwaySelectionScreen,
      transformResponse: (
        res: CMSResponseType<PathwaySelectionScreen, false>
      ) => {
        return {
          ...res.data.attributes,
          illustration: transformImage(res.data.attributes.illustration as any),
        };
      },
    }),

    getExpertCms: builder.query<ExpertCms | undefined, string>({
      query: cmsUrls.expert,
      transformResponse: (res: CMSResponseType<ExpertCmsResponse, true>) => {
        const expert = res.data[0]?.attributes;
        if (!expert) return;
        return {
          ...expert,
          avatar: expert.avatar?.data && transformImage(expert.avatar),
        };
      },
    }),

    getScheduleSessionDetails: builder.query<ScheduleSessionDetails, void>({
      query: cmsUrls.scheduleSessionDetails,
      transformResponse: (res: any) => {
        const data = res.data.attributes;
        return {
          ...data,
          schedule_icon: transformImage(data.schedule_icon),
          redirect_icon: transformImage(data.redirect_icon),
          manage_icon: transformImage(data.manage_icon),
          reschedule_icon: transformImage(data.reschedule_icon),
          cancel_icon: transformImage(data.cancel_icon),
          default_expert_avatar: transformImage(data.default_expert_avatar),
        };
      },
    }),

    getSessionRescheduleRestriction: builder.query<
      RescheduleSessionRestriction,
      void
    >({
      query: cmsUrls.sessionRescheduleRestriction,
      transformResponse: (
        res: CMSResponseType<RescheduleSessionRestriction, false>
      ) => res.data.attributes,
    }),

    getSessionEvents: builder.query<SessionEvent[], void>({
      query: cmsUrls.sessionEvents,
      transformResponse: (res: CMSResponseType<SessionEvent, true>) =>
        transformCmsResponse(res).map((item) => ({
          ...item,
          image: transformImage(item.image),
        })),
    }),

    getChatPage: builder.query<ChatPage, void>({
      query: cmsUrls.chatPage,
      transformResponse: (res: CMSResponseType<ChatPage, false>) => {
        return {
          ...res.data.attributes,
          footer_illustration: transformImage(
            res.data.attributes.footer_illustration as any
          ),
        };
      },
    }),

    getSubscriptionDetails: builder.query<SubscriptionDetails, number>({
      query: cmsUrls.subscriptionDetails,
      transformResponse: (
        res: CMSResponseType<SubscriptionDetailsCms, true>
      ) => {
        const details = res.data[0].attributes;
        return {
          ...details,
          items_included: details.items_included.split("\n"),
        };
      },
    }),

    getSubscriptionProfileDetails: builder.query<SubscriptionDetails, string>({
      query: cmsUrls.subscriptionProfileDetails,
      transformResponse: (
        res: CMSResponseType<SubscriptionDetailsCms, false>
      ) => {
        const details = res.data.attributes;
        return {
          ...details,
          items_included: details.items_included.split("\n"),
        };
      },
    }),

    getDefaultRecommendedPathway: builder.query<Module, void>({
      query: cmsUrls.defaultRecommendedPathway,
      transformResponse: (
        res: CMSResponseType<{ pathway: CMSResponseType<any, false> }, false>
      ) => {
        const pathwayData = res.data.attributes?.pathway;
        const pathway = pathwayData?.data;
        return { ...pathway.attributes, id: pathway.id };
      },
    }),
  }),
});

export const {
  useGetModulesQuery,
  useGetPartsQuery,
  useGetModuleRelationQuery,
  useGetPartGrowthworksQuery,
  useGetYoureOnYourWayQuery,
  useGetTaskCompletedScreensQuery,
  useGetErrorPageContentQuery,
  useGetErrorLoginPaymentPageContentQuery,
  useGetBannersQuery,
  useGetDrawersQuery,
  useGetWelcomeSessionLinkQuery,
  useGetCarouselPageQuery,
  useGetCurrentPartQuery,
  useGetVideoGrowthWorksQuery,
  useGetWistiaTaskPageQuery,
  useGetGrowthworkLabelsQuery,
  useGetContactUsContentQuery,
  useGetPathwaySelectionScreenQuery,
  useGetExpertCmsQuery,
  useGetScheduleSessionDetailsQuery,
  useGetSessionRescheduleRestrictionQuery,
  useGetSessionEventsQuery,
  useGetChatPageQuery,
  useGetSubscriptionDetailsQuery,
  useGetSubscriptionProfileDetailsQuery,
  useGetDefaultRecommendedPathwayQuery,
} = cmsApi;
