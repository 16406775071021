import React, { useEffect } from "react";
import {
  Footer,
  QRCodeContent,
  Wrapper,
} from "src/pages/WelcomeOnboarding/components/QRCodeWelcome/styles";
import { OurRitualLogo } from "src/assets/icons";
import { Typography } from "src/components";
import theme from "src/theme";
import QRCodeScreen from "src/components/QRCode";
import useGenerateShortLink from "src/hooks/useGenerateShortLink";
import LinkToEmailRequest from "src/components/QRCode/LinkToEmailRequest";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isMobile } from "src/utils/isMobile";
import { NativeAppDownloadButton } from "src/components/NativeAppDownloadButton";

const QRCodeWelcome = () => {
  const { showNativeAppQr } = useFlags();
  const shortLink = useGenerateShortLink();

  useEffect(() => {
    if (shortLink && !isMobile()) {
      window.analytics?.track("member_QR_generated");
    }
  }, [shortLink]);

  return (
    <Wrapper>
      {!isMobile() && <OurRitualLogo />}
      <QRCodeContent>
        <Typography variant="headline">
          {showNativeAppQr ? (
            "Here we go"
          ) : (
            <>
              Welcome to <span>OurRitual</span>
            </>
          )}
        </Typography>
        <Typography
          variant="headline3"
          color={theme.palette.grey[600]}
          style={{ marginTop: "8px", marginBottom: "20px" }}
        >
          {showNativeAppQr
            ? "Your journey to a healthier, happier relationship starts now"
            : "Take your relationship to a whole new level"}
        </Typography>
        <Typography
          variant="headline4"
          color={theme.palette.grey[600]}
          style={{ marginBottom: "25px" }}
        >
          {showNativeAppQr
            ? "Download our app to continue your journey on your phone."
            : "To begin your journey as an OurRitual Member, scan the QR code to switch easily to mobile and get access to your personalised path in our app."}
        </Typography>
        {isMobile() ? (
          <NativeAppDownloadButton />
        ) : (
          <QRCodeScreen url={shortLink} size={150} />
        )}
      </QRCodeContent>
      {!isMobile() && (
        <Footer>
          <LinkToEmailRequest />
        </Footer>
      )}
    </Wrapper>
  );
};

export default QRCodeWelcome;
