import LayoutWithoutHeader from "src/components/LayoutWithoutHeader";
import dayjs from "dayjs";
import { useGetMemberQuery } from "src/api/main";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "src/components/Loading";
import {
  HeaderWrapper,
  JoinedDate,
  LogoutButton,
  LogoutButtonText,
  MemberEmail,
  ProfileWrapper,
  Wrapper,
} from "./styles";
import { Typography } from "src/components";
import Avatar from "./components/Avatar";
import ProfileItemList from "./components/ProfileItemList";
import ProfileSections from "./components/ProfileSections";
import { Logout } from "src/assets/icons";
import OldProfile from "./OldProfile";
import { useFlags } from "launchdarkly-react-client-sdk";
import MembershipStatus from "src/components/MembershipStatus";
import { useGetMemberSubscriptionStatusQueryResolver } from "src/api/resolvers";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";
import useIsMatchingExpertChanged from "src/hooks/useIsMatchingExpertChanged";

const Profile = () => {
  const { data: member, isLoading } = useGetMemberQuery();
  const { data: membershipStatus } =
    useGetMemberSubscriptionStatusQueryResolver();
  const { logout } = useAuth0();

  const { memberAppProfile, membershipPaused } = useFlags();
  const {matchingSession} = useFlags()
  const isMatchingCategory = useIsMatchingPlanCategory();
  const createdDate = dayjs(member?.created).format("MMM D YYYY");
  const isMatchginExpertChanged = useIsMatchingExpertChanged();

  if (isLoading) {
    return <Loading />;
  }

  if (!memberAppProfile) {
    return <OldProfile />;
  }

  return (
    <Wrapper>
      <LayoutWithoutHeader>
        <ProfileWrapper>
          <HeaderWrapper>
            <Avatar />
            <div>
              <Typography variant="headline3">
                {member?.first_name} {member?.last_name}
              </Typography>
              <MemberEmail>{member?.email}</MemberEmail>
            </div>
          </HeaderWrapper>
          {membershipStatus?.isSubscriptionPaused && membershipPaused ? (
            <MembershipStatus
              isMembershipPaused={membershipStatus?.isSubscriptionPaused}
              pauseUntilDate={membershipStatus?.pauseUntilDate}
            />
          ) : (
            <>
              <JoinedDate>Member since {createdDate}</JoinedDate>
              <ProfileItemList />
            </>
          )}
          <ProfileSections
            isMembershipPaused={
              membershipStatus?.isSubscriptionPaused && membershipPaused
            }
            isMatching={matchingSession && isMatchingCategory && isMatchginExpertChanged}
          />
          <LogoutButton
            onClick={() => logout({ returnTo: window.location.origin })}
            id="logout-button"
          >
            <Logout />
            <LogoutButtonText>Log out</LogoutButtonText>
          </LogoutButton>
        </ProfileWrapper>
      </LayoutWithoutHeader>
    </Wrapper>
  );
};

export default Profile;
