import React, { useEffect, useState } from "react";
import LayoutWithImage from "../Layout/LayoutWithImage";
import { Button, Input, Typography } from "src/components";
import { Form, SkipButton } from "./styles";
import "src/components/Input/phone_input.css";
import {
  isEmailValid,
  isPhoneNumberValid,
} from "src/components/Input/utils/validators";
import { useGetOnboardingNextStep } from "../../utils/useOnboardingSteps";
import { useAddPartnerMutation, useGetMemberQuery } from "src/api/main";
import useUpdateStatus from "../../utils/useUpdateStatus";
import { useGetOnboardingStatusQueryResolver } from "src/api/resolvers";
import { isMobile } from "src/utils/isMobile";
import { useFlags } from "launchdarkly-react-client-sdk";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";

const fields = [
  { placeholder: "First name", id: "first_name" },
  { placeholder: "Last name", id: "last_name" },
  { placeholder: "Enter phone number", id: "phone_number", type: "tel" },
  { placeholder: "Email address", id: "email", type: "email" },
];

const AddPartnerStep = () => {
  const redirectToNextStep = useGetOnboardingNextStep();
  const [addPartner] = useAddPartnerMutation();
  const { matchingSession, addPartnerFromApp } = useFlags();
  const { data: member } = useGetMemberQuery();
  const updateOnboardingStatus = useUpdateStatus();
  const { data: onboardingStatus } = useGetOnboardingStatusQueryResolver();
  const isMatchingCategory = useIsMatchingPlanCategory();
  useEffect(() => {
    if (
      onboardingStatus?.partner_added ||
      onboardingStatus?.partner_added_skipped
    ) {
      redirectToNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStatus]);

  const [values, setValues] = useState<{ [key: string]: string }>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });

  const [error, setError] = useState<{ [key: string]: string }>({
    email: "",
    phone_number: "",
  });

  const validate = () => {
    let valid = true;
    if (!isEmailValid(values.email)) {
      setError((prev) => ({ ...prev, email: "Email is not valid" }));
      valid = false;
    }

    if (!isPhoneNumberValid(values.phone_number)) {
      setError((prev) => ({
        ...prev,
        phone_number: "Phone number is not valid",
      }));
      valid = false;
    }

    if (member?.email === values.email) {
      setError((prev) => ({
        ...prev,
        email: "Please provide a partner email different from yours",
      }));
      valid = false;
    }

    return valid;
  };

  const onSubmit = () => {
    const isValid = validate();

    if (isValid) {
      const body: any = {
        primary_member: {
          email: member?.email,
          phone_number: member?.phone_number,
          uuid: member?.uuid,
        },
        partner: values,
      };

      addPartner({ memberId: member?.uuid || "", body });
      updateOnboardingStatus({ partner_added: true });
    }
  };
  const skipAddPartner = () => {
    updateOnboardingStatus({
      partner_added_skipped: true,
      partner_added: false,
    });
  };

  return (
    <LayoutWithImage
      image={require(`src/assets/images/onboarding_image3${
        isMobile() ? "-mobile" : ""
      }.webp`)}
      title="Connect your partner"
      subtitle="Please specify who your partner is so that we can get you two on your way to a better relationship."
      position="top"
    >
      <>
        <Form>
          {fields.map((field) => (
            <Input
              {...field}
              error={error[field.id]}
              key={field.id}
              value={values[field.id]}
              onChange={(value: any) =>
                setValues((prev) => ({ ...prev, [field.id]: value }))
              }
            />
          ))}
        </Form>
        <Button
          disabled={Object.values(values).some((value) => !value)}
          id={`onboarding_step-initial`}
          onClick={onSubmit}
        >
          Continue
        </Button>
        {((matchingSession && isMatchingCategory) || addPartnerFromApp) && (
          <SkipButton
            id="onboarding_skip-partner-added"
            onClick={skipAddPartner}
          >
            <Typography variant="button">Add partner later</Typography>
          </SkipButton>
        )}
      </>
    </LayoutWithImage>
  );
};

export default AddPartnerStep;
