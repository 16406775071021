import { Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetCarouselPageQuery } from "src/api/cms";
import {
  useGetActiveModuleQueryResolver,
  useGetYoureOnYourWayQueryResolver,
} from "src/api/resolvers";
import Loading from "src/components/Loading";
import { ROUTES } from "src/types/routes";
import CarouselContent from "./components/CarouselContent";
import Layout from "./components/Layout";
import { InitialStep } from "./styles";

const CarouselPage = () => {
  const { data } = useGetCarouselPageQuery();
  const [step, setStep] = useState(0);
  const ref = useRef<any>();
  const navigate = useNavigate();
  useGetYoureOnYourWayQueryResolver(); // make prefetch of cms content here to be immediately visible on next screen
  const { isFetching: isModuleFetching } = useGetActiveModuleQueryResolver();

  if (!data || isModuleFetching) return <Loading />;

  const onNextStep = () => {
    if (step > 0) ref.current.slickNext();
    if (step < data?.length - 1) setStep((prev) => prev + 1);
    else navigate(ROUTES.JOURNEY_STARTED);
  };

  return (
    <Layout {...data[step]} onClick={onNextStep}>
      {step > 0 ? (
        <CarouselContent
          ref={ref}
          afterChange={(i: number) => setStep(i + 1)}
        />
      ) : (
        <InitialStep>
          <Typography variant="h1">{data[step].title}</Typography>
        </InitialStep>
      )}
    </Layout>
  );
};

export default CarouselPage;
