import React from "react";
import { useGetChatPageQuery } from "src/api/cms";
import { Footer } from "src/components";

const FAQFooter = () => {
  const { data: cmsData } = useGetChatPageQuery();

  if (!cmsData) {
    return <></>;
  }

  return (
    <Footer
      title={cmsData.footer_title}
      subtitle={cmsData.footer_subtitle}
      onClick={() => window.open(cmsData.faq_url, "_blank")}
      button={cmsData.footer_button}
      src={cmsData.footer_illustration}
    />
  );
};

export default FAQFooter;
