import {
  useGetAllowedSessionTypes, useGetCompletedMatchingSessionQueryResolver,
  useGetCompletedWelcomeSessionQueryResolver,
  useGetSessionLinksQueryResolver,
} from "src/api/resolvers";
import useGetSessionLinks, { useGetSessionParams } from "./useGetSessionLinks";
import useActions from "./useActions";
import { SessionType } from "src/api/types";
import {useFlags} from "launchdarkly-react-client-sdk";
import {ROUTES} from "src/types/routes";
import {useNavigate} from "react-router-dom";

const useBookSessionAction = () => {
  const { data: isWelcomeSessionEnded } =
    useGetCompletedWelcomeSessionQueryResolver();
  const { welcomeLink, matchingLink } = useGetSessionLinks();
  const getScheduleSessionParams = useGetSessionParams();
  const { setCalendlyLink, openTypeSelectModal } = useActions();
  const { data: sessionLinks } = useGetSessionLinksQueryResolver();
  const sessionTypes = useGetAllowedSessionTypes();
  const { matchingSession } = useFlags();
  const navigate = useNavigate();
  const { data: matchingSessionDetails, isLoading } =
    useGetCompletedMatchingSessionQueryResolver();
  const onClick = () => {
    if (matchingSessionDetails?.is_matching && !matchingSessionDetails?.is_matching_session_done && matchingSession){
      setCalendlyLink(
        `${matchingLink}${getScheduleSessionParams(SessionType.MATCHING)}`
      );
    }
    else if (matchingSessionDetails?.is_matching && matchingSessionDetails?.is_matching_session_done && matchingSession){
      navigate(ROUTES.PLANS);
    }
    else if (!isWelcomeSessionEnded && !matchingSessionDetails?.is_matching) {
      setCalendlyLink(
        `${welcomeLink}${getScheduleSessionParams(SessionType.WELCOME)}`
      );
    } else if (sessionTypes?.length === 1) {
      setCalendlyLink(
        (sessionLinks?.find((link) => link.type === sessionTypes[0].type)
          ?.link || "") + getScheduleSessionParams(sessionTypes[0].type)
      );
    } else {
      openTypeSelectModal();
    }
  };

  return onClick;
};

export default useBookSessionAction;
