import React, { useEffect } from "react";
import { useSheet, BottomPopup } from "src/components/BottomPopup";
import { useSessionManagerContext } from "../../utils/reducer";
import useActions from "../../utils/useActions";
import ManagerContent from "../ManagerContent";
import Header from "../ManagerContent/Header";
import theme from "src/theme";

const ManagerSheet = () => {
  const { closeManagerSheet } = useActions();
  const sheet = useSheet();
  const { state } = useSessionManagerContext();

  useEffect(() => {
    state.isManagerOpened ? sheet.open() : sheet.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isManagerOpened]);

  const onClose = () => {
    closeManagerSheet();
    sheet.close();
  };

  return (
    <BottomPopup
      Header={<Header onClose={onClose} />}
      {...sheet}
      close={onClose}
      detent="full-height"
      sx={{ backgroundColor: theme.palette.background.default }}
    >
      <ManagerContent />
    </BottomPopup>
  );
};

export default ManagerSheet;
