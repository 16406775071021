import { FeedbackTypes, GwFeedbackType } from "src/api/types";

const gwType = {
  [FeedbackTypes.VIDEO]: "video",
  [FeedbackTypes.QUESTIONNAIRE]: "questionnaire",
  [FeedbackTypes.VIDEO_JOURNAL]: "video journal",
};

const caption =
  "Disclaimer: Your feedback will be sent to OurRitual to help improve our product. Please do no include any personal or sensitive information";
const inputLabel = "Anything else you would like to share?";

const gwContent = (type: GwFeedbackType) => {
  const typeText = gwType[type];

  return {
    caption,
    inputLabel,
    subtitle: `How relevant was the content of this ${typeText} for you?`,
    headline: "Help us improve your Growthwork",
    ids: {
      close: `close_${typeText}_feedback`,
      send: `send_${typeText}_feedback`,
      skip: `skip_${typeText.replace(" ", "")}_feedback`,
    },
  };
};

export const content = {
  [FeedbackTypes.SCHEDULING]: {
    caption,
    inputLabel,
    subtitle: "How easy did you find the scheduling process?",
    headline: "Help us improve the scheduling feature",
    ids: {
      close: "close-feedback",
      send: "send_feedback",
      skip: "skip_feedback",
    },
  },
  [FeedbackTypes.VIDEO]: gwContent(FeedbackTypes.VIDEO),
  [FeedbackTypes.QUESTIONNAIRE]: gwContent(FeedbackTypes.QUESTIONNAIRE),
  [FeedbackTypes.VIDEO_JOURNAL]: gwContent(FeedbackTypes.VIDEO_JOURNAL),
};

export const placeholder = {
  caption: "",
  inputLabel: "",
  subtitle: "",
  headline: "",
  ids: {
    close: "",
    send: "",
    skip: "",
  },
};
