import { useFlags } from "launchdarkly-react-client-sdk";
import {
  useGetDefaultRecommendedPathwayQuery,
  useGetModulesQuery,
} from "src/api/cms";
import { useGetRecommendationsQueryResolver } from "src/api/resolvers";
import { Module } from "src/api/types";

const useGetPathways = () => {
  const { showDefaultRecommendedPathway, appOnboardingQuestionnaire } =
    useFlags();

  const { data: modules, isFetching: isModuleFetching } = useGetModulesQuery();

  const { data: recommendationOrder } = useGetRecommendationsQueryResolver();
  const { data: recommendedPathway, isFetching: isDefaultPathwayFetching } =
    useGetDefaultRecommendedPathwayQuery(undefined, {
      skip: !showDefaultRecommendedPathway,
    });
  const populatedDefaultPathway = modules?.find(
    ({ id }) => id === recommendedPathway?.id
  );

  const orderedPathways = recommendationOrder?.recommended_pathways?.map((id) =>
    modules?.find((module) => String(module.id) === String(id))
  );
  const filteredPathways = (
    appOnboardingQuestionnaire ? orderedPathways : modules
  )?.filter((pathway) => {
    if (!pathway) {
      return false;
    }
    const { id, blocked_for_selection } = pathway;
    return id !== recommendedPathway?.id && !blocked_for_selection;
  }) as Module[];

  const pathways = [
    ...(showDefaultRecommendedPathway && populatedDefaultPathway
      ? [populatedDefaultPathway]
      : []),
    ...(filteredPathways || []),
  ];

  return {
    pathways,
    isLoading: isModuleFetching || isDefaultPathwayFetching,
  };
};

export default useGetPathways;
