import { ReactNode, useCallback } from "react";
import Header from "../Header";
import Navigation from "../Navigation";
import { ContactUsWrapper, Content, Wrapper, PageWrapper } from "./styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import { ContactUsIcon } from "src/assets/icons";

const MainLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const contactUsOnClick = useCallback(() => {
    navigate(ROUTES.CONTACT_US);
  }, [navigate]);

  return (
    <PageWrapper>
      <Wrapper>
        <Header />
        <ContactUsWrapper id="contact-us-button">
          <ContactUsIcon onClick={contactUsOnClick} />
        </ContactUsWrapper>
      </Wrapper>
      <Content>{children}</Content>
      <Navigation />
    </PageWrapper>
  );
};

export default MainLayout;
