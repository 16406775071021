import { styled } from "@mui/material";
import theme from "src/theme";

export const Wrapper = styled("div")({
  marginTop: "24px",
  maxWidth: "285px",

  p: {
    color: theme.palette.grey[600],
  },
});
export const List = styled("ul")({
  color: theme.palette.grey[600],
  paddingLeft: "20px",
});

export const Item = styled("li")({
  listStyle: "disc",
});
