import styled from "@emotion/styled";
import { Button } from "src/components";
import theme from "src/theme";

export const ScheduledSessionWrapper = styled("div")({
  marginTop: "64px",

  button: {
    marginTop: "32px",
  },

  [theme.breakpoints.down("sm")]: {
    marginTop: "54px",
  },
});

export const ScheduleSessionButton = styled(Button)({
  marginTop: "62px",
});
