import { GWPlatforms } from "src/api/types";
import { Label, LabelWrapper } from "./styles";
import lock from "src/assets/images/lock.png";
import { useGetGrowthworkLabelResolver } from "src/api/resolvers";


const GrowthworkLabel = ({
  type,
  disabled,
}: {
  type: GWPlatforms;
  disabled?: boolean;
}) => {
  const data = useGetGrowthworkLabelResolver(type);

  return (
    <LabelWrapper>
      {disabled && <img src={lock} alt="lock" />}
      <Label disabled={disabled}>
        <img src={data?.icon} alt="label_icon" />
        {data?.label}
      </Label>
    </LabelWrapper>
  );
};

export default GrowthworkLabel;
