import { Fragment } from "react";
import { useGetDrawersQuery } from "src/api/cms";
import { useGetDrawersState } from "src/store/selectors";
import DefaultDrawer from "./components/DefaultDrawer";
import { componentsMapping } from "./utils/drawerComponentsMapping";

const Drawers = () => {
  const { data: drawers } = useGetDrawersQuery();
  const { activeDrawer } = useGetDrawersState();

  return (
    <>
      {drawers?.map((drawer) => {
        const Component = componentsMapping[drawer.buttonId] || DefaultDrawer;
        if (activeDrawer !== drawer.query_param)
          return <Fragment key={drawer.buttonId} />;
        return <Component key={drawer.buttonId} drawer={drawer} />;
      })}
    </>
  );
};

export default Drawers;
