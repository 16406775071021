import styled from "@emotion/styled";

export const Form = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 20,

  margin: "26px 0"
});

export const SkipButton = styled("div")({
  marginTop: "20px",
  marginBottom: "15px",
});