import {
  useGetCompletedSessionsCountQueryResolver,
  useGetMemberFeedbacksQueryResolver,
} from "src/api/resolvers";
import useActions from "./useActions";
import { useCalendlyEventListener } from "react-calendly";
import { FeedbackTypes } from "src/api/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAppDispatch } from "src/store/hooks";
import { openFeedback } from "src/store/feedbackReducer";

const SESSIONS_COUNT_BEFORE_FEEDBACK = 2;

const useSchedulingFeedbackListener = () => {
  const dispatch = useAppDispatch();

  const { showSchedulingFeedback } = useFlags();

  const { closeCalendly, closeManagerSheet } = useActions();

  const { data: completedSessionsCount } =
    useGetCompletedSessionsCountQueryResolver();
  const { data: memberFeedback } = useGetMemberFeedbacksQueryResolver();

  useCalendlyEventListener({
    onEventScheduled: () => {
      if (
        showSchedulingFeedback &&
        completedSessionsCount &&
        completedSessionsCount >= SESSIONS_COUNT_BEFORE_FEEDBACK &&
        !memberFeedback?.find(({ type }) => type === FeedbackTypes.SCHEDULING)
      ) {
        dispatch(openFeedback({ type: FeedbackTypes.SCHEDULING }));
        closeManagerSheet();
      }
      closeCalendly();
    },
  });
};

export default useSchedulingFeedbackListener;
