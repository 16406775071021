import React from "react";
import IllustrationPageLayout from "src/components/IllustrationPageLayout";
import illustration from "src/assets/images/join_session-illustration.webp";
import { Button, Typography } from "src/components";
import { PageWrapper, Wrapper } from "./styles";
import ExpertFooter from "./components/ExpertFooter";
import { useGetSessionDetailsQueryResolver } from "src/api/resolvers";
import Loading from "src/components/Loading";
import { SessionStatus } from "src/api/types";
import JoinSession from "../JoinSession";
import useJoinSession from "src/hooks/useJoinSession";
import InvalidSession from "../JoinSession/SessionStates/InvalidSession";
import useSessionTitle from "src/hooks/useSessionTitle";

const JoinSessionUnauthorized = () => {
  useSessionTitle("OurRitual Session");

  const {
    data: session,
    isFetching,
    isError,
  } = useGetSessionDetailsQueryResolver();
  const { joinSession } = useJoinSession();

  if (isError) {
    return <InvalidSession />;
  }

  if (isFetching) {
    return <Loading />;
  }

  if (!session || session.status !== SessionStatus.SCHEDULED) {
    return <JoinSession />;
  }

  return (
    <PageWrapper>
      <IllustrationPageLayout
        TitleText={
          <>
            Join session <span>as Member</span>
          </>
        }
        SubtitleText="Your video session with your relationship expert is about to start."
        illustration={illustration}
      >
        <Wrapper>
          <Button id="join-session-unauthorized" onClick={() => joinSession()}>
            Join session
          </Button>
          <Typography>For OurRitual Members only</Typography>
        </Wrapper>
      </IllustrationPageLayout>
      <ExpertFooter />
    </PageWrapper>
  );
};

export default JoinSessionUnauthorized;
