import React from "react";
import { SUPPORT_EMAIL } from "src/constants/support";
import { Link } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import theme from "src/theme";
import { LoginTypes } from "src/types/login";

export const SUBTITLE = {
  default: (value: string) => (
    <>
      We couldn’t find an account with <span>{value}</span>.
    </>
  ),
  email: (email: string) => (
    <>
      We couldn’t find an OurRitual account with the email address{" "}
      <span>{email}</span>. Check for typos, and{" "}
      <span style={{ color: theme.palette.primary.main }}>
        <Link
          to={ROUTES.DESKTOP_VIEW}
          state={{ type: LoginTypes.QRCODE_EMAIL }}
        >
          try generating the QR code
        </Link>
      </span>{" "}
      again or if you believe this is an error{" "}
      <span style={{ color: theme.palette.primary.main }}>
        <a href={SUPPORT_EMAIL}>contact our support team</a>
      </span>
      .
    </>
  ),
  phoneNumber: (phoneNumber: string) => (
    <>
      We couldn’t find an OurRitual account with this phone number:{" "}
      <span>{phoneNumber}</span>. You might have not finished your account
      set-up. Please review your messages from OurRitual to confirm your sign-up
      is complete.
    </>
  ),
};
